import * as React from "react";
import QRCode from "react-qr-code";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

interface QRCodeProps {
    link: string;
    text: string;
}

export default function LinkWithQRCode({
    link,
    text,
}: QRCodeProps): JSX.Element {
    const handleCopyClick = React.useCallback(async () => {
        await navigator.clipboard.writeText(link);
    }, [link]);

    return (
        <Stack component="div" spacing={1}>
            <Typography color="primary.main" textAlign="center">
                {text}
            </Typography>
            <Button
                fullWidth
                variant="contained"
                onClick={handleCopyClick}
                title="Copy link to Clipboard"
                endIcon={<ContentCopyIcon />}
            >
                Copy Link
            </Button>
            <Typography textAlign="center">{link}</Typography>
            <div
                style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: "20px",
                    width: "80%",
                }}
            >
                <QRCode
                    value={link}
                    fgColor="#152756"
                    viewBox="0 0 256 256"
                    style={{
                        margin: "auto",
                        height: "auto",
                        width: "100%",
                    }}
                />
            </div>
        </Stack>
    );
}
