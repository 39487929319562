import { model, convert } from "@volley/physics";

export default function position2Grid(
    point: model.CourtPoint,
    cols: number,
    rows: number,
    physicsModelName: model.PhysicsModelName,
    sport: model.Sport,
    extend = true,
): [number, number] {
    convert.setPhysicsModel(physicsModelName, sport);
    // this represents only half the court where players are
    // include both add/duece sides
    // it is example generated by rows 4, col 4 as input
    //   ________________________________
    //   |  -2,4 | -1,4  |  1,4  |  2,4  |
    //   |_______|_______|_______|_______|
    //   |  -2,3 | -1,3  |  1,3  |  2,3  |
    //   |_______|_______|_______|_______|
    //   |  -2,2 | -1,2  |  1,2  |  2,2  |
    //   |_______|_______|_______|_______|
    //   |  -2,1 | -1,1  |  1,1  |  2,1  |
    //   |_______|_______|_______|_______|
    //   HERE IS THE NET HERE IS THE NET
    //         TRAINER IS DOWN HERE
    //
    let row = 0;
    let col = 0;
    // going all the way to the wall for measurements
    const HALF_PLATFORM_LENGTH =
        convert.getPhysicsModel().courtGeometry.PLATFORM_LENGTH / 2.0;
    const { PLATFORM_WIDTH } = convert.getPhysicsModel().courtGeometry;
    const ySize = HALF_PLATFORM_LENGTH / rows;
    const xSize = PLATFORM_WIDTH / cols;
    const rowl = point.y / ySize;
    const coll = point.x / xSize;
    row = rowl > 0.0 ? Math.ceil(rowl) : Math.floor(rowl);
    col = coll > 0.0 ? Math.ceil(coll) : Math.floor(coll);
    // if extend is true, then points that are outside the boundary of
    // the edge cells(e.g.col = 3 or - 3) are mapped into those edge cells;
    // i.e.those cells extend outwards / upwards (but NOT downwards through
    // the net).  NOTE: we don't want to capture spectators or others outside
    // the court, so extend should be used ONLY in single-person test
    // scenarios or AFTER off-court filtering is applied. The latter
    // situation could arise if we're interpolating a missing target for
    // a frame and the footprint of this 'good' target is a bit outside
    // the play area.
    if (extend) {
        col = col > cols / 2 ? cols / 2 : col;
        col = col < -cols / 2 ? -cols / 2 : col;
        row = row > rows ? rows : row;
        row = rows < 0 ? 1 : row;
    }
    return [col, row];
}
