import type { TagCategoryWithRelations } from "@volley/data";

export function getTagCategoryHeading(
    tagCategory: TagCategoryWithRelations | null,
): string {
    if (!tagCategory) {
        return "Unknown";
    }
    return `${tagCategory.label} - (${tagCategory.mode === "SINGLE" ? "Choose One" : "Choose Many"})`;
}
