import * as React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { OffsetResult, UserWithRelations } from "@volley/data";

import { fetchApi } from "../../util";
import { logFetchError } from "../../util/fetchApi";
import { pluralize } from "../../util/text";

export default function UserLookup(): JSX.Element {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const searchRef = React.useRef<HTMLInputElement>();
    const [search, setSearch] = React.useState<string>();
    const [loading, setLoading] = React.useState(false);
    const [hasSearched, setHasSearched] = React.useState(false);
    const [error, setError] = React.useState<string | null>(null);
    const [showDialog, setShowDialog] = React.useState(false);
    const params = React.useMemo(() => ({ q: search || undefined }), [search]);
    const [userMatches, setUserMatches] = React.useState<
        OffsetResult<UserWithRelations>
    >({
        count: 0,
        offset: 0,
        result: [],
    });
    const [shareUsers, setShareUsers] = React.useState<UserWithRelations[]>([]);

    React.useEffect(() => {
        async function performSearch() {
            if (params.q) {
                const session = searchParams.get("session");
                if (!session) {
                    throw new Error("Invalid session ID provided");
                }
                setLoading(true);
                const url = `/api/users/search?q=${params.q}&limit=20&offset=0`;
                return fetchApi<OffsetResult<UserWithRelations>>(url);
            }

            return { count: 0, offset: 0, result: [] };
        }

        performSearch()
            .then((matches) => {
                setUserMatches(matches);
                setHasSearched(true);
            })
            .catch((e) => logFetchError(e))
            .finally(() => setLoading(false));
    }, [params, searchParams]);

    const handleShare = React.useCallback(async () => {
        const session = searchParams.get("session");
        if (!session) {
            throw new Error("Invalid session ID provided");
        }

        const workoutsMaybe = searchParams.get("workouts");
        const workoutIds = workoutsMaybe
            ? workoutsMaybe.split(",").map((w) => Number.parseInt(w, 10))
            : undefined;

        try {
            await fetchApi("/api/sessions/share", "POST", {
                sessionId: Number.parseInt(session, 10),
                workoutIds,
                userIds: shareUsers.map((s) => s.id),
            });
            setError(null);
        } catch {
            setError("An error occured while sharing the session");
        } finally {
            setShowDialog(true);
        }
    }, [searchParams, shareUsers]);

    return (
        <Stack spacing={1} paddingTop="10px">
            <Paper
                component="form"
                onSubmit={(e) => {
                    e.preventDefault();
                    setSearch(searchRef.current?.value);
                }}
                sx={{
                    p: "4px 4px",
                    display: "flex",
                    alignItems: "center",
                    borderRadius: "24px",
                }}
            >
                <InputBase
                    sx={{
                        ml: 1,
                        flex: 1,
                        borderRadius: "50%",
                    }}
                    placeholder="Search for users..."
                    autoFocus
                    inputRef={searchRef}
                />
                <IconButton
                    type="button"
                    sx={{
                        p: "10px",
                        borderRadius: "24px",
                        color: (t) => t.palette.secondary.contrastText,
                        backgroundColor: (t) => t.palette.secondary.main,
                    }}
                    aria-label="search"
                    onClick={() => setSearch(searchRef.current?.value)}
                >
                    <SearchIcon />
                </IconButton>
            </Paper>
            {!loading && hasSearched && (
                <Typography>{`Showing ${userMatches.result.length} of ${userMatches.count}`}</Typography>
            )}
            <Card
                sx={{
                    height: "24vh",
                    padding: "5px",
                    overflowY: "scroll",
                }}
            >
                <Stack spacing={1}>
                    {!loading && !hasSearched && (
                        <Typography>Start typing to search...</Typography>
                    )}
                    {loading && <CircularProgress />}
                    {!loading &&
                        userMatches.result.map((u) => {
                            const selected =
                                shareUsers.find((i) => i.id === u.id) !==
                                undefined;
                            return (
                                <Stack
                                    key={`${u.id}-search-result`}
                                    direction="row"
                                    justifyContent="space-between"
                                >
                                    <Typography>{`${u.username} [${u.firstName} ${u.lastName}]`}</Typography>
                                    <IconButton
                                        size="small"
                                        color="secondary"
                                        onClick={() => {
                                            if (selected) {
                                                setShareUsers(
                                                    shareUsers.filter(
                                                        (i) => i.id !== u.id,
                                                    ),
                                                );
                                            } else {
                                                const updated = [
                                                    ...shareUsers,
                                                    u,
                                                ];
                                                setShareUsers(updated);
                                            }
                                        }}
                                        disabled={selected}
                                    >
                                        <AddCircleIcon />
                                    </IconButton>
                                </Stack>
                            );
                        })}
                </Stack>
            </Card>
            <Typography>{`Share with the following ${pluralize(shareUsers.length, "user")}:`}</Typography>
            <Card
                sx={{
                    height: "24vh",
                    padding: "5px",
                    overflowY: "scroll",
                }}
            >
                <Stack height="100%" spacing={1}>
                    {shareUsers.map((u) => (
                        <Stack
                            key={`${u.id}-user-share`}
                            direction="row"
                            justifyContent="space-between"
                        >
                            <Typography>{`${u.username} [${u.firstName} ${u.lastName}]`}</Typography>
                            <IconButton
                                size="small"
                                color="warning"
                                onClick={() => {
                                    setShareUsers(
                                        shareUsers.filter((i) => i.id !== u.id),
                                    );
                                }}
                            >
                                <RemoveCircleIcon />
                            </IconButton>
                        </Stack>
                    ))}
                    <div
                        style={{
                            marginTop: "auto",
                            width: "100%",
                        }}
                    >
                        <Button
                            color="secondary"
                            variant="contained"
                            disabled={shareUsers.length === 0}
                            fullWidth
                            onClick={() => handleShare()}
                            sx={{
                                marginTop: "auto",
                            }}
                        >
                            Share
                        </Button>
                    </div>
                </Stack>
            </Card>
            <Dialog open={showDialog}>
                <DialogTitle>
                    {error ? "An Error Occurred" : "Success"}
                </DialogTitle>
                {error && <DialogContent>{error}</DialogContent>}
                {!error && (
                    <DialogContent>
                        {`Your workout is now available to ${shareUsers.map((s) => s.username).join(",")}`}
                    </DialogContent>
                )}
                <DialogActions>
                    <Button
                        variant="contained"
                        color="info"
                        onClick={() => {
                            if (error) {
                                setShowDialog(false);
                            } else {
                                navigate(-1);
                            }
                        }}
                    >
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </Stack>
    );
}
