import * as React from "react";

import CloseIcon from "@mui/icons-material/Close";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Rating from "@mui/material/Rating";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

import { terseDateFormat } from "../../util";

export interface UserFeedback {
    sessionDate: string;
    rating: number;
    comment: string;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

interface Props {
    userId: number;
    userFeedback: UserFeedback[];
}

export default function ClubFeedbackDialog({ userFeedback, userId }: Props) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const ratingSum = userFeedback
        .map((feedback) => feedback.rating)
        .reduce((partialSum, rating) => partialSum + rating, 0);
    const avgRating = userFeedback.length
        ? ratingSum / userFeedback.length
        : NaN;

    return (
        <>
            <Typography
                onClick={handleClickOpen}
                data-testid={`ClubFeedbackDialog_openLink_${userId}`}
            >
                <Rating
                    name="read-only"
                    size="small"
                    value={avgRating}
                    precision={0.25}
                    readOnly
                />
                <OpenInFullIcon sx={{ mb: 1, fontSize: ".8em" }} />
            </Typography>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-rating-and-comment"
                open={open}
                sx={{ minWidth: "300px" }}
            >
                <DialogTitle
                    sx={{ m: 0, p: 2 }}
                    id="customized-dialog-rating-and-comment"
                >
                    Feedback
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent
                    dividers
                    sx={{ width: "250px" }}
                    data-testid="ClubFeedbackDialog_dialogContent"
                >
                    {userFeedback
                        .sort((uf1, uf2) => {
                            const d1 = new Date(uf1.sessionDate);
                            const d2 = new Date(uf2.sessionDate);

                            if (d1 < d2) return 1;
                            if (d2 < d1) return -1;
                            return 0;
                        })
                        .map((feedback) => (
                            <div
                                key={feedback.sessionDate}
                                style={{ marginBottom: 30 }}
                            >
                                <Stack direction="row" spacing={1.25}>
                                    <span>
                                        {terseDateFormat(
                                            new Date(feedback.sessionDate),
                                        )}
                                    </span>
                                    <Divider orientation="vertical" flexItem />
                                    <Rating
                                        name="read-only"
                                        size="medium"
                                        value={feedback.rating}
                                        readOnly
                                    />
                                </Stack>
                                <span>{feedback.comment || "comment n/a"}</span>
                            </div>
                        ))}
                </DialogContent>
            </BootstrapDialog>
        </>
    );
}
