import * as React from "react";
import { useSearchParams } from "react-router-dom";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { fetchApi } from "../../util";
import { logFetchError } from "../../util/fetchApi";
import { pluralize } from "../../util/text";

import LinkWithQRCode from "./QRCode";
import TrackingList, { TrackingListItem } from "./TrackingList";

// TODO: Move this to helper function maybe?
function multiLineRegex(expressions: RegExp[]): RegExp {
    return new RegExp(expressions.map((exp) => exp.source).join(""));
}

const emailMatchRegex = multiLineRegex([
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))/, // Username
    /@/, // at symbol
    /((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, // domain
]);

function validateEmail(email: string) {
    return email.toLowerCase().match(emailMatchRegex);
}

export default function UserInvite(): JSX.Element {
    const [searchParams] = useSearchParams();
    const [emailInput, setEmailInput] = React.useState<string | undefined>();
    const [emails, setEmails] = React.useState<TrackingListItem[]>([]);
    const [emailError, setEmailError] = React.useState<string | undefined>();
    const [link, setLink] = React.useState("");
    const [loading, setLoading] = React.useState(false);

    const handleAdd = React.useCallback(() => {
        if (!emailInput || !validateEmail(emailInput)) {
            setEmailError("Invalid email address");
            return;
        }

        if (emails.find((e) => e.display === emailInput)) {
            setEmailError("Email already in list");
            return;
        }

        const updated = [...emails, { display: emailInput, key: emailInput }];
        setEmails(updated);
        setEmailError(undefined);
    }, [emailInput, emails]);

    const handleSubmit = React.useCallback(() => {
        async function generateCode(): Promise<string> {
            const session = searchParams.get("session");
            if (!session) {
                throw new Error("Invalid session ID provided");
            }

            const workoutsMaybe = searchParams.get("workouts");
            const workoutIds = workoutsMaybe
                ? workoutsMaybe.split(",").map((w) => Number.parseInt(w, 10))
                : undefined;
            const { code } = await fetchApi<{ code: string }>(
                "/api/invites",
                "POST",
                {
                    sessionId: Number.parseInt(session, 10),
                    workoutIds,
                    emails: emails.map((e) => e.display),
                },
            );
            return code;
        }

        setLoading(true);

        generateCode()
            .then((code) => {
                const { protocol, host } = window.location;
                const url = `${protocol}//${host}/signup/email?invite=${code}`;
                setLink(url);
            })
            .catch((e) => logFetchError(e))
            .finally(() => setLoading(false));
    }, [searchParams, emails]);

    return (
        <Stack spacing={1} paddingTop="10px">
            {loading && <CircularProgress />}
            {link === "" && !loading && (
                <Stack spacing={1}>
                    <Stack direction="row">
                        <TextField
                            fullWidth
                            disabled={loading}
                            type="email"
                            size="small"
                            label="Email"
                            error={emailError !== undefined}
                            helperText={emailError}
                            value={emailInput}
                            onChange={(e) => setEmailInput(e.target.value)}
                        />
                        <IconButton
                            color="secondary"
                            onClick={() => handleAdd()}
                        >
                            <AddCircleIcon />
                        </IconButton>
                    </Stack>
                    <Typography>{`Invite the following ${pluralize(emails.length, "user")}:`}</Typography>
                    <TrackingList
                        sx={{
                            height: "60vh",
                            overflowY: "scroll",
                            padding: "5px",
                        }}
                        actionButtonTitle="Generate Link"
                        onActionButtonClick={() => handleSubmit()}
                        listIconType="remove"
                        listItems={emails}
                        listKey="new-emails"
                        onIconButtonClick={(key) => {
                            const updated = emails.filter((e) => e.key !== key);
                            setEmails(updated);
                        }}
                    />
                </Stack>
            )}
            {link !== "" && (
                <LinkWithQRCode
                    link={link}
                    text="Send the below link to the new user or have them scan the QR code."
                />
            )}
        </Stack>
    );
}
