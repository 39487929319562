import * as React from "react";

import SwapVertIcon from "@mui/icons-material/SwapVert";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box/Box";
import Grid from "@mui/material/Grid/Grid";
import TextField from "@mui/material/TextField/TextField";
import Typography from "@mui/material/Typography/Typography";

import { useStatus } from "../../hooks/status";
import { LiftModal, useLift } from "../../hooks/useLift";

import BigButton from "./BigButton";

export default function Lift(): JSX.Element {
    const [errMsg, setErrMsg] = React.useState("");
    const { status } = useStatus();
    const {
        error,
        height,
        lowerHead,
        isLifting,
        liftRange,
        setHeight,
        stop,
        tickUp,
        tickDown,
    } = useLift();
    const [targetHeight, setTargetHeight] = React.useState(height.toString());
    const hasError = errMsg !== "" || error !== null;
    const moveDown = React.useCallback(async () => {
        setTargetHeight("0");
        await lowerHead();
    }, [setTargetHeight, lowerHead]);

    const setPosition = React.useCallback(async () => {
        const parsed = parseFloat(targetHeight);
        if (Number.isNaN(parsed)) {
            setErrMsg("Not a valid number!");
        } else if (parsed >= liftRange.min && parsed <= liftRange.max) {
            await setHeight(parsed);
        } else {
            setErrMsg(
                `Height must be between ${liftRange.min} and ${liftRange.max} inches`,
            );
        }
    }, [setHeight, setErrMsg, liftRange, targetHeight]);
    const faultMessage =
        status === null ||
        status.fault === null ||
        status.fault.failures.length === 0
            ? ""
            : status.fault.failures[0].message;

    return (
        <Grid container spacing={3}>
            {isLifting && (
                <LiftModal
                    stop={stop}
                    targetHeight={parseFloat(targetHeight)}
                />
            )}
            {status?.fault !== null && (
                <Grid item xs={12}>
                    <Box>
                        <Typography align="center" variant="h6">
                            {faultMessage}
                        </Typography>
                    </Box>
                </Grid>
            )}
            <Grid item xs={12}>
                <Typography variant="h2" align="center">
                    Set Trainer Height
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography align="center">
                    Current Height (inches):&nbsp;
                    {height}
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <BigButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isLifting}
                    onClick={async () =>
                        setTargetHeight((await tickDown()).toString())
                    }
                >
                    -
                </BigButton>
            </Grid>
            <Grid item xs={6}>
                <BigButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isLifting}
                    onClick={async () =>
                        setTargetHeight((await tickUp()).toString())
                    }
                >
                    +
                </BigButton>
            </Grid>
            <Grid item xs={12}>
                <Box
                    alignContent="center"
                    justifyContent="center"
                    display="flex"
                >
                    <TextField
                        label="New Height (inches)"
                        name="value"
                        value={targetHeight.toString()}
                        variant="outlined"
                        onChange={(evt) => {
                            setTargetHeight(evt.target.value);
                            setErrMsg("");
                        }}
                    />
                </Box>
                {hasError && <Alert severity="error">{errMsg || error}</Alert>}
            </Grid>
            <Grid item xs={12}>
                <BigButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    endIcon={<SwapVertIcon />}
                    disabled={isLifting}
                    onClick={() => setPosition()}
                >
                    Move to New Height
                </BigButton>
            </Grid>
            <Grid item xs={12}>
                <BigButton
                    type="submit"
                    variant="contained"
                    color="primary"
                    endIcon={<SwapVertIcon />}
                    disabled={isLifting}
                    onClick={moveDown}
                >
                    Lower Head all the Way
                </BigButton>
            </Grid>
        </Grid>
    );
}
