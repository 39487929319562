import logger from "../../../log";

const DEFAULT_COLOR = "black";
const DEFAULT_LINE_WIDTH = 1;

interface BoundingBox {
    xmin: number;
    ymin: number;
    width: number;
    height: number;
}

interface Point {
    x: number;
    y: number;
}

export interface StyleOptions {
    strokeStyle?: string | CanvasGradient | CanvasPattern;
    fillStyle?: string | CanvasGradient | CanvasPattern;
    lineWidth?: number;
}

export function drawLine(
    ctx: CanvasRenderingContext2D,
    start: Point,
    end: Point,
    options?: StyleOptions,
    scaleFactor = 1,
) {
    let { x: startX, y: startY } = start;
    if (typeof startX !== "number" || typeof startY !== "number") {
        startX = 0;
        startY = 0;
    }

    let { x: endX, y: endY } = end;
    if (typeof endX !== "number" || typeof endY !== "number") {
        endX = 0;
        endY = 0;
    }

    if (startX === endX && startY === endY) {
        // a line that goes nowhere is not a line

        logger.warn("Invalid line", { start, end });
        return;
    }

    ctx.beginPath();
    ctx.moveTo(start.x * scaleFactor, start.y * scaleFactor);
    ctx.lineTo(end.x * scaleFactor, end.y * scaleFactor);

    ctx.strokeStyle = options?.strokeStyle || DEFAULT_COLOR;
    ctx.lineWidth = options?.lineWidth || DEFAULT_LINE_WIDTH;

    ctx.stroke();
    ctx.closePath();
}

export function drawRectangle(
    ctx: CanvasRenderingContext2D,
    bbox: BoundingBox,
    options?: StyleOptions,
    scaleFactor = 1,
) {
    // in earlier versions of vision, protobufs won't include xmin and ymin if they are 0
    let { xmin, ymin } = bbox;
    if (typeof xmin !== "number" || typeof ymin !== "number") {
        xmin = 0;
        ymin = 0;
    }

    if (typeof bbox.width !== "number" || typeof bbox.height !== "number") {
        logger.warn("Invalid bounding box", bbox);
        return;
    }

    ctx.beginPath();
    ctx.rect(
        bbox.xmin * scaleFactor,
        bbox.ymin * scaleFactor,
        bbox.width * scaleFactor,
        bbox.height * scaleFactor,
    );

    if (options?.fillStyle) {
        ctx.fillStyle = options.fillStyle;
        ctx.fill();
    }

    if (options?.strokeStyle || options?.lineWidth) {
        ctx.strokeStyle = options.strokeStyle || DEFAULT_COLOR;
        ctx.lineWidth = options.lineWidth || DEFAULT_LINE_WIDTH;
    }

    ctx.stroke();
    ctx.closePath();
}

export function drawCircle(
    ctx: CanvasRenderingContext2D,
    center: Point,
    radius: number,
    options?: StyleOptions,
    scaleFactor = 1,
) {
    if (typeof center.x !== "number" || typeof center.y !== "number") {
        logger.warn("Invalid center point", center);
        return;
    }

    if (typeof radius !== "number" && radius <= 0) {
        logger.warn("Invalid radius", radius);
        return;
    }

    ctx.beginPath();
    ctx.arc(
        center.x * scaleFactor,
        center.y * scaleFactor,
        radius * scaleFactor,
        0,
        2 * Math.PI,
    );

    if (options?.fillStyle) {
        ctx.fillStyle = options.fillStyle;
        ctx.fill();
    }

    if (options?.strokeStyle || options?.lineWidth) {
        ctx.strokeStyle = options.strokeStyle || DEFAULT_COLOR;
        ctx.lineWidth = options.lineWidth || DEFAULT_LINE_WIDTH;
    }

    ctx.stroke();
    ctx.closePath();
}

export function drawDiamond(
    ctx: CanvasRenderingContext2D,
    center: Point,
    size: number,
    options?: StyleOptions,
    scaleFactor = 1,
) {
    if (typeof center.x !== "number" || typeof center.y !== "number") {
        logger.warn("Invalid center point", center);
        return;
    }

    if (typeof size !== "number" && size <= 0) {
        logger.warn("Invalid size", size);
        return;
    }

    const halfSize = size / 2;
    const topLeft: Point = { x: center.x - halfSize, y: center.y };
    const topRight: Point = { x: center.x, y: center.y - halfSize };
    const bottomRight: Point = { x: center.x + halfSize, y: center.y };
    const bottomLeft: Point = { x: center.x, y: center.y + halfSize };

    ctx.beginPath();
    ctx.moveTo(topLeft.x * scaleFactor, topLeft.y * scaleFactor);
    ctx.lineTo(topRight.x * scaleFactor, topRight.y * scaleFactor);
    ctx.lineTo(bottomRight.x * scaleFactor, bottomRight.y * scaleFactor);
    ctx.lineTo(bottomLeft.x * scaleFactor, bottomLeft.y * scaleFactor);
    ctx.lineTo(topLeft.x * scaleFactor, topLeft.y * scaleFactor);

    if (options?.fillStyle) {
        ctx.fillStyle = options.fillStyle;
        ctx.fill();
    }

    if (options?.strokeStyle || options?.lineWidth) {
        ctx.strokeStyle = options.strokeStyle || DEFAULT_COLOR;
        ctx.lineWidth = options.lineWidth || DEFAULT_LINE_WIDTH;
    }

    ctx.stroke();
    ctx.closePath();
}
