import * as React from "react";

import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import type { GridColDef, GridRowParams } from "@mui/x-data-grid";

import { MatchUnified } from "@volley/data";

import AutoCompleteDateWrapper from "../AutoComplete/AutoCompleteDateWrapper";
import MatchesTable from "../Matches/MatchesTable";
import {
    getOpponents,
    getOppositeSide,
    getPlayerKey,
    getPlayerName,
    getWinner,
    isOpponent,
} from "../utils/labels";
import { withinTimeFilter } from "../utils/util";

import BaseTable, { GridRowBaseDef } from "./BaseTable";

interface OpponentRow extends GridRowBaseDef {
    opponentName: string | null;
    opponentMatchCount: number;
    loss: number;
    win: number;
    winPercentage: number;
}

type OpponentStat = Record<
    string,
    {
        numMatches: number;
        numWin: number;
        numLoss: number;
        opponentId: number;
        opponentName: string;
    }
>;

interface Props {
    matches: MatchUnified[];
    playerId: number;
}

export default function OpponentTable({
    matches,
    playerId,
}: Props): JSX.Element {
    const [open, setOpen] = React.useState(false);

    // table selection
    const [selectedOppRow, setSelectedOppRow] =
        React.useState<OpponentRow | null>(null);

    // filters
    const [autoCompleteInputs, setAutoCompleteInputs] = React.useState<
        GridRowBaseDef[] | null
    >(null);
    const [daysAgo, setDaysAgo] = React.useState(NaN);

    const filteredMatched = matches.filter((match) =>
        withinTimeFilter(match, daysAgo),
    );
    const opponentStats: OpponentStat = {};

    filteredMatched.forEach((match) => {
        const opponents = getOpponents(match, playerId);
        opponents.forEach((opponent) => {
            if (opponent) {
                // this dynamically targets player ids which are numbers
                const opponentId = match[getPlayerKey(opponent)] as number;
                // this dynamically targets player names which are string
                const opponentName = match[getPlayerName(opponent)] as string;

                if (!(opponentId in opponentStats)) {
                    opponentStats[opponentId] = {
                        opponentId,
                        opponentName,
                        numMatches: 0,
                        numWin: 0,
                        numLoss: 0,
                    };
                }

                opponentStats[opponentId].numMatches += 1;

                if (getWinner(match.winner) === opponent.side) {
                    opponentStats[opponentId].numLoss += 1;
                }

                if (
                    getWinner(match.winner) === getOppositeSide(opponent.side)
                ) {
                    opponentStats[opponentId].numWin += 1;
                }
            }
        });
    });

    const rows: OpponentRow[] = Object.keys(opponentStats).map((key) => ({
        autoCompleteValue: opponentStats[key].opponentName.replace(/\./g, ""),
        id: opponentStats[key].opponentId,
        opponentName: opponentStats[key].opponentName.replace(/\./g, ""),
        opponentMatchCount: opponentStats[key].numMatches,
        loss: opponentStats[key].numLoss,
        win: opponentStats[key].numWin,
        winPercentage: parseFloat(
            (
                (opponentStats[key].numWin / opponentStats[key].numMatches) *
                100
            ).toFixed(1),
        ),
    }));

    rows.sort((row1, row2) => {
        if (!row1.opponentName || !row2.opponentName) {
            return 1;
        }

        return row1.opponentName.localeCompare(row2.opponentName);
    });

    const cols: GridColDef[] = [
        {
            field: "opponentName",
            headerName: "Opponent",
            align: "left",
            sortable: true,
            disableColumnMenu: true,
            width: 100,
            renderCell: (params) => {
                const row = params.row as OpponentRow;

                if (!row.opponentName) {
                    return <span>n/a</span>;
                }

                const [firstName, lastName] = row.opponentName.split(" ");

                return (
                    <Stack>
                        <span>{firstName}</span>
                        <span>{lastName}</span>
                    </Stack>
                );
            },
        },
        {
            field: "opponentMatchCount",
            headerName: "#",
            align: "left",
            sortable: true,
            disableColumnMenu: true,
            width: 1,
        },
        {
            field: "win",
            headerName: "W",
            align: "left",
            sortable: true,
            width: 1,
            disableColumnMenu: true,
        },
        {
            field: "loss",
            headerName: "L",
            align: "left",
            sortable: true,
            width: 1,
            disableColumnMenu: true,
        },
        {
            field: "winPercentage",
            headerName: "%",
            align: "left",
            sortable: true,
            width: 1,
            disableColumnMenu: true,
        },
    ];

    const handleOnRowClick = (e: GridRowParams<OpponentRow>) => {
        const { row } = e;
        setSelectedOppRow(row);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Dialog open={open} onClose={handleClose} scroll="body">
                <DialogTitle>Opponent Matches</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent sx={{ pl: 1, pr: 1 }}>
                    <MatchesTable
                        matches={filteredMatched.filter((match) => {
                            if (
                                selectedOppRow?.id &&
                                isOpponent(match, playerId, selectedOppRow.id)
                            ) {
                                return true;
                            }

                            return false;
                        })}
                        playerId={playerId}
                    />
                </DialogContent>
            </Dialog>
            <Box style={{ marginBottom: 5 }} component="div">
                <AutoCompleteDateWrapper
                    label="search opponents"
                    handleDaysFilter={setDaysAgo}
                    initialValues={autoCompleteInputs || []}
                    selectionOptions={rows}
                    handleSelectValues={setAutoCompleteInputs}
                    multiple
                />
            </Box>
            <BaseTable
                rows={rows.filter((row) => {
                    if (autoCompleteInputs && autoCompleteInputs.length > 0) {
                        return autoCompleteInputs
                            .map((input) => input.autoCompleteValue)
                            .includes(row.autoCompleteValue);
                    }

                    return true;
                })}
                cols={cols}
                initialSortField="opponentName"
                handleOnRowClick={handleOnRowClick}
            />
        </>
    );
}
