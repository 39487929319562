import * as React from "react";

import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Drawer from "@mui/material/Drawer";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { ContentProvider } from "@volley/data";

import { pluralize } from "../../../../../util/text";

import PublisherSelect from "./PublisherSelect";

export interface PublisherFilterDrawerProps {
    count: number;
    loading: boolean;
    selectedProviders: ContentProvider[];
    open: boolean;
    allProviders: ContentProvider[];
    updateCount: (providers: ContentProvider[]) => void;
    onConfirmFilter: (providers: ContentProvider[]) => void;
    onCancel: () => void;
}

export default function PublisherFilterDrawer({
    count,
    loading,
    selectedProviders,
    open,
    allProviders,
    updateCount,
    onConfirmFilter,
    onCancel,
}: PublisherFilterDrawerProps): JSX.Element {
    return (
        <Drawer
            anchor="bottom"
            open={open}
            onCanPlay={() => onCancel()}
            onClose={() => onCancel()}
            PaperProps={{
                sx: {
                    borderRadius: "16px 16px 0 0",
                    backgroundColor: (t) => t.palette.primary.main,
                },
            }}
        >
            <Stack
                spacing={2}
                sx={{
                    padding: "10px",
                }}
            >
                <Typography
                    variant="h3"
                    color="white"
                    textAlign="center"
                    sx={{
                        textTransform: "none",
                    }}
                >
                    Published By
                </Typography>
                <PublisherSelect
                    selectedProviders={selectedProviders}
                    providers={allProviders}
                    updateSelected={(updated) => updateCount(updated)}
                />
                {loading && (
                    <Stack
                        direction="row"
                        justifyContent="space-around"
                        sx={{
                            width: "100%",
                        }}
                    >
                        <CircularProgress size={36.5} />
                    </Stack>
                )}
                {!loading && count > 0 && (
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => onConfirmFilter(selectedProviders)}
                        fullWidth
                        sx={{
                            mt: 2,
                            borderRadius: "16px",
                        }}
                    >
                        {`Show ${count} ${pluralize(count, "Result")}`}
                    </Button>
                )}
                {!loading && count === 0 && (
                    <Typography
                        variant="button"
                        color="white"
                        textAlign="center"
                    >
                        No Matches Found
                    </Typography>
                )}
                <Button
                    color="primary"
                    variant="text"
                    onClick={() => onCancel()}
                    sx={{
                        borderRadius: "16px",
                        color: "white",
                    }}
                >
                    Cancel
                </Button>
            </Stack>
        </Drawer>
    );
}
