import * as React from "react";

import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import TableAutoComplete, { AutoCompleteProps } from "./TableAutoComplete";

interface Props extends AutoCompleteProps {
    handleDaysFilter: (days: number) => void;
}

function selectionIndexToDays(index: string) {
    switch (index) {
        case "0":
            return "30";
        case "1":
            return "60";
        case "2":
            return "90";
        case "3":
            return "365";
        default:
            return NaN;
    }
}

export default function AutoCompleteDateWrapper({
    label,
    initialValues,
    multiple,
    selectionOptions,
    handleDaysFilter,
    handleSelectValue = () => {},
    handleSelectValues = () => {},
}: Props) {
    const [timeSelection, setTimeSelection] = React.useState("4");

    const handleChange = (event: SelectChangeEvent) => {
        const selection = event.target.value.toString();
        setTimeSelection(selection);
        handleDaysFilter(Number(selectionIndexToDays(selection)));
    };

    return (
        <Grid container spacing={1}>
            <Grid item xs={7.5}>
                <TableAutoComplete
                    label={label}
                    initialValues={initialValues}
                    multiple={multiple}
                    selectionOptions={selectionOptions}
                    handleSelectValue={(row) => handleSelectValue(row)}
                    handleSelectValues={handleSelectValues}
                />
            </Grid>
            <Grid item xs={4.5}>
                <FormControl fullWidth>
                    <InputLabel>days</InputLabel>
                    <Select
                        value={timeSelection}
                        label="Age"
                        onChange={handleChange}
                    >
                        <MenuItem value="0">{`${selectionIndexToDays("0")} days`}</MenuItem>
                        <MenuItem value="1">{`${selectionIndexToDays("1")} days`}</MenuItem>
                        <MenuItem value="2">{`${selectionIndexToDays("2")} days`}</MenuItem>
                        <MenuItem value="3">1 year</MenuItem>
                        <MenuItem value="4">all time</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    );
}
