import * as React from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";

import CancelIcon from "@mui/icons-material/Cancel";
import SendIcon from "@mui/icons-material/Send";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { pairedFetchApi, logFetchError } from "../../util/fetchApi";
import { useStatus } from "../hooks/status";

const CATEGORIES = [
    ["", ""],
    ["aiming_motors", "Aiming Motors"],
    ["ball_feed", "Ball Feed"],
    ["batteries", "Batteries"],
    ["camera", "Camera"],
    ["connection", "Connection"],
    ["led", "LED"],
    ["lift", "Lift"],
    ["localization", "Localization"],
    ["throwing_motors", "Throwing Motors"],
    ["other", "Other"],
];

export default function ReportIssue(): JSX.Element {
    const navigate = useNavigate();
    const { status } = useStatus();

    const [category, setCategory] = React.useState("");
    const [description, setDescription] = React.useState("");

    const onSubmit = React.useCallback(
        async (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            try {
                await pairedFetchApi(status?.clientId, "/api/issue", "POST", {
                    sessionId: status?.session?.id ?? null,
                    softwareVersion: status?.softwareVersion ?? "<unknown>",
                    category: category.length ? category : "other",
                    description,
                });
            } catch (ex) {
                logFetchError(ex, "Failed to submit issue");
            } finally {
                navigate("/status");
            }
        },
        [
            status?.session?.id,
            status?.softwareVersion,
            status?.clientId,
            category,
            description,
            navigate,
        ],
    );

    return (
        <Grid container justifyContent="center">
            <Grid item md={6} xs={12} component="form" onSubmit={onSubmit}>
                {status?.clientId && (
                    <>
                        <Typography variant="h2" component="h1">
                            Report Issue
                        </Typography>
                        <FormControl fullWidth style={{ marginBottom: "1rem" }}>
                            <InputLabel htmlFor="category">Category</InputLabel>
                            <Select
                                native
                                value={category}
                                onChange={(e) => setCategory(e.target.value)}
                                inputProps={{
                                    name: "category",
                                    id: "category",
                                }}
                            >
                                {CATEGORIES.map(([value, label]) => (
                                    <option key={value} value={value}>
                                        {label}
                                    </option>
                                ))}
                            </Select>
                        </FormControl>
                        <TextField
                            label="Description"
                            multiline
                            fullWidth
                            value={description}
                            onChange={(e) =>
                                setDescription(e.currentTarget.value)
                            }
                            style={{ marginBottom: "2rem" }}
                        />
                        <ButtonGroup fullWidth>
                            <Button
                                type="submit"
                                color="primary"
                                variant="contained"
                                startIcon={<SendIcon />}
                            >
                                Send
                            </Button>
                            <Button
                                variant="contained"
                                startIcon={<CancelIcon />}
                                component={RouterLink}
                                to="/status"
                            >
                                Cancel
                            </Button>
                        </ButtonGroup>
                    </>
                )}
                {!status?.clientId && (
                    <>
                        <Typography variant="h2" component="h1">
                            Report Issue
                        </Typography>
                        <Typography variant="h3">
                            You aren&apos;t currently connected to a trainer.
                        </Typography>
                        <Button
                            variant="contained"
                            component={RouterLink}
                            to="/"
                        >
                            Home
                        </Button>
                    </>
                )}
            </Grid>
        </Grid>
    );
}
