import * as React from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { MatchUnified } from "@volley/data";

import theme from "../../theme";
import { fetchApi } from "../../util";

import FloatingButton from "./Dialogs/FeedbackDialog";
import MenuSettings from "./Dialogs/MenuSettings";
import PlayerPTIGrid, { PTI_LABEL } from "./LineChart/LineChartGrid";
import MatchesTable from "./Matches/MatchesTable";
import PlayerPTISummary from "./PlayerPTISummary";
import LocationTable from "./WinLossTables/LocationsTable";
import OpponentTable from "./WinLossTables/OpponentTable";
import PartnerTable from "./WinLossTables/PartnerTable";
import SpreadTable from "./WinLossTables/SpreadTable";
import { DataSet } from "./types";
import { getMatchesAsc, transformToCoordinates } from "./utils/util";

function matchHasSet(match: MatchUnified, set: string) {
    if (set === "All") {
        return true;
    }

    const set2 =
        match.set_2_team_1_score != null && match.set_2_team_2_score != null;
    const set3 =
        match.set_3_team_1_score != null && match.set_3_team_2_score != null;
    const set2Team1Scored =
        match.set_2_team_1_score && match.set_2_team_1_score > 0;
    const set2Team2Scored =
        match.set_2_team_2_score && match.set_2_team_2_score > 0;
    const set2SomeTeamScored = set2Team1Scored || set2Team2Scored;
    const set3Team1Scored =
        match.set_3_team_1_score && match.set_3_team_1_score > 0;
    const set3Team2Scored =
        match.set_3_team_2_score && match.set_3_team_2_score > 0;
    const set3SomeTeamScored = set3Team1Scored || set3Team2Scored;

    // make sure set 2 was played and that set3 was not played
    if (
        set === "2" &&
        set2 &&
        set2SomeTeamScored &&
        !(set3 && set3SomeTeamScored)
    ) {
        return true;
    }

    // simply make sure set3 was played
    if (set === "3" && set3 && set3SomeTeamScored) {
        return true;
    }

    return false;
}

export default function PlayerPTI(): JSX.Element {
    const navigate = useNavigate();
    const location = useLocation();
    const locationState = location.state as
        | { aptaPlayerId?: number }
        | undefined;

    const [playerId, setPlayerId] = React.useState<number | null>(
        locationState?.aptaPlayerId ?? null,
    );

    // chartjs
    const [dataSets, setDataSets] = React.useState<DataSet[]>([]);
    const [matches, setMatches] = React.useState<MatchUnified[]>([]);

    // dropdown
    const [view, setView] = React.useState(
        location.pathname.split("/")[2] ?? "summary",
    );
    const handleChange = (event: SelectChangeEvent) => {
        setView(event.target.value);
        navigate(event.target.value, { relative: "route", replace: true });
    };

    // settings dialog
    const [settingsSets, setSettingsSets] = React.useState("All");

    // miscellaneous
    const [loading, setLoading] = React.useState(false);
    const [errors, setErrors] = React.useState<string[]>([]);

    const updateDataSets = (pId: number, newMatches: MatchUnified[]) => {
        const matchesAsc = getMatchesAsc(newMatches);
        const dataCoordinates = transformToCoordinates(matchesAsc, pId);

        setDataSets([
            {
                label: PTI_LABEL,
                backgroundColor: [theme.palette.primary.main],
                borderColor: theme.palette.secondary.main,
                fill: false,
                data: dataCoordinates,
                pointHoverRadius: 10,
            },
        ]);

        setMatches(matchesAsc);
        setLoading(false);
    };

    React.useEffect(() => {
        setLoading(true);

        if (!playerId) {
            fetchApi<{ playerId: string }>("/api/pti/player-id/mine")
                .then((res) => setPlayerId(parseInt(res.playerId, 10)))
                .catch((error: Error) =>
                    setErrors((currentErrors) => {
                        currentErrors.push(error.message);
                        return [...currentErrors];
                    }),
                );
        }

        if (!playerId) return;
        fetchApi<MatchUnified[]>("/api/pti/matches", "GET")
            .then((response) => updateDataSets(playerId, response))
            .catch((error: Error) =>
                setErrors((currentErrors) => {
                    currentErrors.push(error.message);
                    return [...currentErrors];
                }),
            );
    }, [playerId]);

    if (errors.length > 0) {
        return (
            <Alert
                severity="error"
                data-testid="PlayerPTI_errorAlert"
                sx={{ m: 2 }}
            >
                <AlertTitle>Error</AlertTitle>
                <ul>
                    {errors.map((item) => (
                        <li key={item}>{item}</li>
                    ))}
                </ul>
            </Alert>
        );
    }

    if (loading || !playerId) {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: "50vh",
                }}
            >
                <CircularProgress size={50} thickness={4} />
            </div>
        );
    }

    const handleSettingsSetsChange = (set: string) => {
        setSettingsSets(set);
    };

    const matchesFiltered = matches.filter((match) =>
        matchHasSet(match, settingsSets),
    );
    const viewsExcludeSettingsIcon = ["summary", "pti-graph", "locations"];

    return (
        <Card sx={{ padding: 1, mt: 1, position: "relative" }}>
            <FloatingButton />
            <Box
                sx={{
                    marginTop: 1,
                    marginBottom: 1,
                    display: "flex",
                    justifyContent: "center",
                    padding: 0,
                }}
                component="div"
            >
                <FormControl
                    sx={{
                        width: "50%",
                        ml: !viewsExcludeSettingsIcon.includes(view)
                            ? "24px"
                            : 0,
                    }}
                >
                    <InputLabel>view</InputLabel>
                    <Select
                        defaultValue={view}
                        value={view}
                        label="view"
                        onChange={handleChange}
                    >
                        <MenuItem value="summary">Summary</MenuItem>
                        <MenuItem value="pti-graph">PTI Graph</MenuItem>
                        <MenuItem value="matches">Matches</MenuItem>
                        <MenuItem value="partners">Partners</MenuItem>
                        <MenuItem value="opponents">Opponents</MenuItem>
                        <MenuItem value="locations">Locations</MenuItem>
                        <MenuItem value="spread">Spread</MenuItem>
                    </Select>
                </FormControl>
            </Box>
            {!viewsExcludeSettingsIcon.includes(view) && (
                <>
                    <MenuSettings
                        settingsSets={settingsSets}
                        handleSettingsSetsChange={handleSettingsSetsChange}
                    />
                    <Box component="div" textAlign="center" m={3}>
                        <Stack>
                            <Typography variant="body2">{`Data Based on ${settingsSets} Sets`}</Typography>
                        </Stack>
                    </Box>
                </>
            )}
            <Routes>
                <Route
                    path="pti-graph"
                    element={
                        <PlayerPTIGrid
                            dataSets={dataSets}
                            matches={matches}
                            playerId={playerId}
                            setDataSets={setDataSets}
                        />
                    }
                />
                <Route
                    path="matches"
                    element={
                        <MatchesTable
                            matches={matchesFiltered}
                            playerId={playerId}
                            showRecord
                        />
                    }
                />
                <Route
                    path="partners"
                    element={
                        <PartnerTable
                            matches={matchesFiltered}
                            playerId={playerId}
                        />
                    }
                />
                <Route
                    path="opponents"
                    element={
                        <OpponentTable
                            matches={matchesFiltered}
                            playerId={playerId}
                        />
                    }
                />
                <Route
                    path="locations"
                    element={
                        <LocationTable
                            matches={matchesFiltered}
                            playerId={playerId}
                        />
                    }
                />
                <Route
                    path="spread"
                    element={
                        <SpreadTable
                            matches={matchesFiltered}
                            playerId={playerId}
                        />
                    }
                />
                <Route
                    path="*"
                    element={
                        <PlayerPTISummary
                            matches={matchesFiltered}
                            playerId={playerId}
                        />
                    }
                />
            </Routes>
        </Card>
    );
}
