import * as React from "react";
import { useSearchParams } from "react-router-dom";

import type { SelectChangeEvent } from "@mui/material/Select";

import { OffsetResult } from "@volley/data";

import fetchApi, { logFetchError } from "../../util/fetchApi";

type MapFn<F, T> = (from: F) => T;

export const DEFAULT_ROWS_PER_PAGE = 50;
const SEARCH_PAGE_KEY = "page";
const SEARCH_ROWS_PER_PAGE_KEY = "rowsPerPage";

type Options<T, F> = Partial<{
    id: string;
    initialRowsPerPage: number;
    params: Record<string, string | undefined>;
    mapFn: MapFn<F, T>;
}>;

function searchParamName(key: string, id?: string) {
    const prefix = id ? `${id}-` : "";
    return `${prefix}${key}`;
}

export default function usePaginatedData<T, F = unknown>(
    baseUrl: string,
    options: Options<T, F> = {},
) {
    const { id, params, mapFn } = options;
    const searchPageParamName = searchParamName(SEARCH_PAGE_KEY, id);
    const searchRowsPerPageParamName = searchParamName(
        SEARCH_ROWS_PER_PAGE_KEY,
        id,
    );
    const [searchParams, setSearchParams] = useSearchParams();
    const searchPage = searchParams.get(searchPageParamName);
    const searchRowsPerPage = searchParams.get(searchRowsPerPageParamName);

    const [count, setCount] = React.useState(0);
    const [page, setPage] = React.useState(
        searchPage ? parseInt(searchPage, 10) : 0,
    );
    const initialRowsPerPage = searchRowsPerPage
        ? parseInt(searchRowsPerPage, 10)
        : (options.initialRowsPerPage ?? DEFAULT_ROWS_PER_PAGE);
    const [rowsPerPage, setRowsPerPage] = React.useState(initialRowsPerPage);

    const [data, setData] = React.useState<T[]>([]);
    const [loading, setLoading] = React.useState(true);
    const [fetchError, setFetchError] = React.useState<string>("");

    const url = React.useMemo(() => {
        const filteredParams = params ?? {};
        Object.keys(filteredParams).forEach((key) => {
            if (
                filteredParams[key] === "" ||
                filteredParams[key] === null ||
                filteredParams[key] === undefined
            ) {
                // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
                delete filteredParams[key];
            }
        });

        const query = new URLSearchParams({
            limit: rowsPerPage.toString(),
            offset: (page * rowsPerPage).toString(),
            ...filteredParams,
        });

        return `${baseUrl}?${query.toString()}`;
    }, [params, page, rowsPerPage, baseUrl]);

    const fetchData = React.useCallback(async () => {
        setLoading(true);
        try {
            const responseData: OffsetResult<F> = await fetchApi(url);
            setCount(responseData.count);
            setData(
                mapFn
                    ? responseData.result.map(mapFn)
                    : (responseData.result as unknown as T[]),
            );
        } finally {
            setLoading(false);
        }
    }, [url, mapFn]);

    const onRowsPerPageChange = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const next = parseInt(e.target.value, 10);
            setPage(0);
            setRowsPerPage(next);
            setSearchParams((search) => {
                search.delete(searchPageParamName);
                if (
                    next !==
                    (options.initialRowsPerPage ?? DEFAULT_ROWS_PER_PAGE)
                ) {
                    search.set(searchRowsPerPageParamName, next.toString());
                } else {
                    search.delete(searchRowsPerPageParamName);
                }
                return search;
            });
        },
        [
            setSearchParams,
            searchPageParamName,
            searchRowsPerPageParamName,
            options.initialRowsPerPage,
        ],
    );

    const onPageChange = React.useCallback(
        (
            _e:
                | React.MouseEvent<HTMLButtonElement>
                | SelectChangeEvent<number>
                | null,
            pageNumber: number,
        ) => {
            setPage(pageNumber);
            setSearchParams((search) => {
                if (pageNumber > 0) {
                    search.set(searchPageParamName, pageNumber.toString());
                } else {
                    search.delete(searchPageParamName);
                }
                return search;
            });
        },
        [setSearchParams, searchPageParamName],
    );

    React.useEffect(() => {
        setFetchError("");
        fetchData().catch((e) => {
            setFetchError((e as Error).message);
            logFetchError(e);
        });
    }, [fetchData]);

    return React.useMemo(
        () => ({
            rowsPerPage,
            onRowsPerPageChange,
            count,
            page,
            onPageChange,
            loading,
            data,
            fetchError,
        }),
        [
            rowsPerPage,
            onRowsPerPageChange,
            count,
            page,
            onPageChange,
            loading,
            data,
            fetchError,
        ],
    );
}
