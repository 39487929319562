type ShotPayload = Partial<{
    id: string;
    key: string;
    name: string;
    aim: Partial<{
        pan: number;
        tilt: number;
        lift: number;
    }>;
    throw: Partial<{
        rpm?: Partial<{
            top: number;
            left: number;
            right: number;
        }>;
        speed?: number;
        spin?: {
            x: number;
            y: number;
        };
    }>;
}>;

export enum ActionType {
    Initialize,
    UpdateMotorRpm,
    UpdatePan,
    UpdateTilt,
    UpdateLift,
}

interface Initialize {
    type: ActionType.Initialize;
    shot: ShotPayload;
}

interface UpdateMotorRpm {
    type: ActionType.UpdateMotorRpm;
    motor: "top" | "left" | "right" | "all";
    rpm: number;
}

interface UpdatePan {
    type: ActionType.UpdatePan;
    pan: number;
}

interface UpdateTilt {
    type: ActionType.UpdateTilt;
    tilt: number;
}

export type Action = Initialize | UpdateMotorRpm | UpdatePan | UpdateTilt;

export interface State {
    topRpm: number;
    leftRpm: number;
    rightRpm: number;
    pan: number;
    tilt: number;
    initialized: boolean;
}

export const initialState: State = {
    pan: 0,
    tilt: 0,
    topRpm: 0,
    leftRpm: 0,
    rightRpm: 0,
    initialized: false,
};

export function reducer(state: State, action: Action): State {
    switch (action.type) {
        case ActionType.Initialize:
            return {
                ...state,
                topRpm: action.shot.throw?.rpm?.top ?? initialState.topRpm,
                leftRpm: action.shot.throw?.rpm?.left ?? initialState.leftRpm,
                rightRpm:
                    action.shot.throw?.rpm?.right ?? initialState.rightRpm,
                pan: action.shot.aim?.pan ?? initialState.pan,
                tilt: action.shot.aim?.tilt ?? initialState.tilt,
                initialized: true,
            };
        case ActionType.UpdateMotorRpm: {
            switch (action.motor) {
                case "top":
                    return { ...state, topRpm: action.rpm };
                case "left":
                    return { ...state, leftRpm: action.rpm };
                case "right":
                    return { ...state, rightRpm: action.rpm };
                case "all":
                    return {
                        ...state,
                        topRpm: action.rpm,
                        leftRpm: action.rpm,
                        rightRpm: action.rpm,
                    };
                default:
                    throw new Error("Invalid thrower motor");
            }
        }
        case ActionType.UpdatePan:
            return { ...state, pan: action.pan };
        case ActionType.UpdateTilt:
            return { ...state, tilt: action.tilt };
        default:
            return { ...state };
    }
}
