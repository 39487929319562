import * as React from "react";

import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import Drawer from "@mui/material/Drawer";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { Tag, TagCategoryWithRelations } from "@volley/data";

import { pluralize } from "../../../../../util/text";

import TagCategorySelect from "./TagCategorySelect";
import { getTagCategoryHeading } from "./util";

interface TagFilterDrawerProps {
    count: number;
    loading: boolean;
    selectedFilter: TagCategoryWithRelations | null;
    selectedTags: Record<number, Tag[]>;
    updateCount: (tags: Record<number, Tag[]>) => void;
    onConfirmFilter: () => void;
    onCancel: () => void;
}

export default function TagFilterDrawer({
    count,
    loading,
    selectedFilter,
    selectedTags,
    updateCount,
    onConfirmFilter,
    onCancel,
}: TagFilterDrawerProps): JSX.Element {
    return (
        <Drawer
            anchor="bottom"
            open={selectedFilter !== null}
            onClose={() => onCancel()}
            PaperProps={{
                sx: {
                    borderRadius: "16px 16px 0 0",
                    backgroundColor: (t) => t.palette.primary.main,
                },
            }}
        >
            <Stack
                spacing={1}
                sx={{
                    padding: "10px",
                }}
            >
                <Typography
                    variant="h3"
                    color="white"
                    textAlign="center"
                    sx={{
                        textTransform: "none",
                    }}
                >
                    Filter
                </Typography>
                <Typography color="white">
                    {getTagCategoryHeading(selectedFilter)}
                </Typography>
                {selectedFilter && selectedFilter.mode === "SINGLE" && (
                    <Stack
                        spacing={0.5}
                        direction="row"
                        flexWrap="wrap"
                        rowGap={0.5}
                    >
                        {selectedFilter.tags
                            .sort((a, b) => {
                                if (a.name < b.name) {
                                    return -1;
                                }
                                if (a.name > b.name) {
                                    return 1;
                                }
                                return 0;
                            })
                            .map((tag) => (
                                <Chip
                                    key={tag.id}
                                    color="info"
                                    label={tag.label}
                                    onClick={() => {
                                        const updated = { ...selectedTags };
                                        updated[selectedFilter.id] = [tag];
                                        updateCount(updated);
                                    }}
                                    onDelete={
                                        selectedTags[selectedFilter.id]?.[0]
                                            ?.id === tag.id
                                            ? () => {
                                                  const updated = {
                                                      ...selectedTags,
                                                  };
                                                  delete updated[
                                                      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
                                                      selectedFilter.id
                                                  ];
                                                  updateCount(updated);
                                              }
                                            : undefined
                                    }
                                />
                            ))}
                    </Stack>
                )}
                {selectedFilter && selectedFilter.mode === "MULTIPLE" && (
                    <TagCategorySelect
                        tagCategory={selectedFilter}
                        selectedTags={selectedTags}
                        updateSelected={(updated) => updateCount(updated)}
                    />
                )}
                {loading && (
                    <Stack
                        direction="row"
                        justifyContent="space-around"
                        sx={{
                            width: "100%",
                        }}
                    >
                        <CircularProgress size={36.5} />
                    </Stack>
                )}
                {!loading && count > 0 && (
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => onConfirmFilter()}
                        fullWidth
                        sx={{
                            mt: 2,
                            borderRadius: "16px",
                        }}
                    >
                        {`Show ${count} ${pluralize(count, "Result")}`}
                    </Button>
                )}
                {!loading && count === 0 && (
                    <Typography
                        variant="button"
                        color="white"
                        textAlign="center"
                    >
                        No Matches Found
                    </Typography>
                )}
                <Button
                    color="info"
                    variant="text"
                    onClick={() => {
                        onCancel();
                    }}
                    sx={{
                        borderRadius: "16px",
                        color: "white",
                    }}
                >
                    Cancel
                </Button>
            </Stack>
        </Drawer>
    );
}
