import * as React from "react";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import type { UserStats } from "@volley/data";

import fetchApi, { logFetchError } from "../../util/fetchApi";
import { useCurrentUser } from "../hooks/currentUser";

interface PlayerStatItemProps {
    value: number;
    label: string;
    color: string;
}
interface StatTextProps {
    value: string;
    color: string;
}

function StatText({ value, color }: StatTextProps): JSX.Element {
    return (
        <Typography
            variant="h1"
            sx={{
                color,
                fontSize: "2.25rem",
            }}
            align="right"
        >
            {value}
        </Typography>
    );
}
interface StatLabelProps {
    label: string;
}
function StatLabel({ label }: StatLabelProps): JSX.Element {
    return (
        <Typography variant="h5" sx={{ fontFamily: "Fire Sans" }} align="right">
            {label}
        </Typography>
    );
}

interface StatSubLabelProps {
    value: string;
    color: string;
}
function StatSubLabel({ value, color }: StatSubLabelProps): JSX.Element {
    return (
        <Typography
            align="right"
            variant="h5"
            sx={{
                color,
                paddingRight: 1,
            }}
        >
            {value}
        </Typography>
    );
}

function PlayerStatItem({
    value,
    label,
    color,
}: PlayerStatItemProps): JSX.Element {
    return (
        <Grid sx={{ width: "30%" }}>
            <StatLabel label={label} />
            <StatText value={value.toLocaleString()} color={color} />
        </Grid>
    );
}

const zeroPadDigit = (digit: number) =>
    digit > 9 ? digit.toString() : `0${digit}`;

function PlayerStatTimeItem({
    value,
    label,
    color,
}: PlayerStatItemProps): JSX.Element {
    const days = Math.floor(value / 1440);
    const hours = Math.floor((value % 1440) / 60);
    const minutes = value % 60;

    return (
        <Grid sx={{ width: "30%" }}>
            <StatLabel label={label} />
            <Box display="flex" justifyContent="flex-end" sx={{ p: 0, m: 0 }}>
                {days > 0 && (
                    <Box>
                        <StatText value={`${days.toString()}:`} color={color} />
                        <StatSubLabel value="DAYS" color={color} />
                    </Box>
                )}
                {hours > 0 && (
                    <Box>
                        <StatText
                            value={`${zeroPadDigit(hours)}:`}
                            color={color}
                        />
                        <StatSubLabel value="HOURS" color={color} />
                    </Box>
                )}
                <Box>
                    <StatText value={zeroPadDigit(minutes)} color={color} />
                    <StatSubLabel value="MINUTES" color={color} />
                </Box>
            </Box>
        </Grid>
    );
}

export default function PlayerStats(): JSX.Element {
    const { currentUser } = useCurrentUser();
    const [ballsHit, setBallsHit] = React.useState(0);
    const [totalWorkouts, setTotalWorkouts] = React.useState(0);
    const [sessions, setSessions] = React.useState(0);
    const [timePlayed, setTimePlayed] = React.useState(0);
    const [errorMessage, setErrorMessage] = React.useState("");

    React.useEffect(() => {
        const fetch = async (userId: number) => {
            const stats = await fetchApi<UserStats>(
                `/api/users/${userId}/stats`,
            );
            setBallsHit(stats.ballsThrown);
            setTotalWorkouts(stats.workoutsPlayed);
            setSessions(stats.sessionsPlayed);
            setTimePlayed(stats.minutesPlayed);
            setErrorMessage("");
        };
        if (currentUser && currentUser.id) {
            fetch(currentUser.id).catch((e) => {
                logFetchError(e);
                if (e instanceof Error) {
                    setErrorMessage(e.message);
                } else {
                    setErrorMessage("Failed to load player sqtats.");
                }
            });
        }
    }, [currentUser]);

    return (
        <Box component="div">
            {errorMessage && (
                <Typography color="error.main">{errorMessage}</Typography>
            )}
            <Typography
                variant="h2"
                color="primary.contrastText"
                sx={{ paddingBottom: 2, fontSize: "1.5rem" }}
            >
                My Stats
            </Typography>
            <Card elevation={6} sx={{ padding: "5px" }}>
                <Stack>
                    <Stack direction="row" justifyContent="space-around">
                        <PlayerStatItem
                            value={ballsHit}
                            label="Balls Hit:"
                            color="#39BF68"
                        />
                        <PlayerStatItem
                            value={totalWorkouts}
                            label="Workouts Played:"
                            color="#FD9C28"
                        />
                    </Stack>
                    <Stack direction="row" justifyContent="space-around">
                        <PlayerStatItem
                            value={sessions}
                            label="Sessions:"
                            color="#D732B2"
                        />
                        <PlayerStatTimeItem
                            value={timePlayed}
                            label="Time Played:"
                            color="#132751"
                        />
                    </Stack>
                </Stack>
            </Card>
        </Box>
    );
}
