import * as React from "react";

import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import type { GridColDef, GridRowParams } from "@mui/x-data-grid";

import { MatchUnified } from "@volley/data";

import AutoCompleteDateWrapper from "../AutoComplete/AutoCompleteDateWrapper";
import MatchesTable, { getPlayerPtiChange } from "../Matches/MatchesTable";
import {
    getOppositeSide,
    getPartner,
    getPlayerKey,
    getPlayerName,
    getWinner,
    isPartner,
} from "../utils/labels";
import { formatTwoDecimalWithSign, withinTimeFilter } from "../utils/util";

import BaseTable, { GridRowBaseDef } from "./BaseTable";

interface PartnerRow extends GridRowBaseDef {
    partnerName: string | null;
    partnerMatchCount: number;
    pti: number;
    loss: number;
    win: number;
    winPercentage: number;
}

type PartnerStat = Record<
    string,
    {
        numMatches: number;
        numWin: number;
        numLoss: number;
        partnerId: number;
        partnerName: string;
        pti: number;
    }
>;

interface Props {
    matches: MatchUnified[];
    playerId: number;
}

export default function PartnerTable({
    matches,
    playerId,
}: Props): JSX.Element {
    const [open, setOpen] = React.useState(false);

    // table selection
    const [selectedPartnerRow, setSelectedPartnerRow] =
        React.useState<PartnerRow | null>(null);

    // filters
    const [autoCompleteInputs, setAutoCompleteInputs] = React.useState<
        GridRowBaseDef[] | null
    >(null);
    const [daysAgo, setDaysAgo] = React.useState(NaN);

    const partnerStats: PartnerStat = {};
    const filteredMatched = matches.filter((match) =>
        withinTimeFilter(match, daysAgo),
    );

    filteredMatched.forEach((match) => {
        const partner = getPartner(match, playerId);

        if (partner) {
            // this dynamically targets player ids which are numbers
            const partnerId = match[getPlayerKey(partner)] as number;
            // this dynamically targets player names which are strings
            const partnerName = match[getPlayerName(partner)] as string;

            if (!(partnerId in partnerStats)) {
                partnerStats[partnerId] = {
                    partnerId,
                    partnerName,
                    pti: 0,
                    numMatches: 0,
                    numWin: 0,
                    numLoss: 0,
                };
            }

            partnerStats[partnerId].numMatches += 1;
            partnerStats[partnerId].pti += getPlayerPtiChange(match, playerId);

            if (getWinner(match.winner) === partner.side) {
                partnerStats[partnerId].numWin += 1;
            }

            if (getWinner(match.winner) === getOppositeSide(partner.side)) {
                partnerStats[partnerId].numLoss += 1;
            }
        }
    });

    const rows: PartnerRow[] = Object.keys(partnerStats).map((key) => ({
        autoCompleteValue: partnerStats[key].partnerName.replace(/\./g, ""),
        id: partnerStats[key].partnerId,
        partnerName: partnerStats[key].partnerName.replace(/\./g, ""),
        partnerMatchCount: partnerStats[key].numMatches,
        pti: partnerStats[key].pti,
        loss: partnerStats[key].numLoss,
        win: partnerStats[key].numWin,
        winPercentage: parseFloat(
            (
                (partnerStats[key].numWin / partnerStats[key].numMatches) *
                100
            ).toFixed(1),
        ),
    }));

    rows.sort((row1, row2) => {
        if (!row1.partnerName || !row2.partnerName) {
            return 1;
        }

        return row1.partnerName.localeCompare(row2.partnerName);
    });

    const cols: GridColDef[] = [
        {
            field: "partnerName",
            headerName: "Partner",
            align: "left",
            sortable: true,
            disableColumnMenu: true,
            width: 100,
            renderCell: (params) => {
                const row = params.row as PartnerRow;

                if (!row.partnerName) {
                    return <span>n/a</span>;
                }

                const [firstName, lastName] = row.partnerName.split(" ");

                return (
                    <Stack>
                        <span>{firstName}</span>
                        <span>{lastName}</span>
                    </Stack>
                );
            },
        },
        {
            field: "partnerMatchCount",
            headerName: "#",
            align: "left",
            sortable: true,
            disableColumnMenu: true,
            width: 1,
        },
        {
            field: "win",
            headerName: "W",
            align: "left",
            sortable: true,
            width: 1,
            disableColumnMenu: true,
        },
        {
            field: "loss",
            headerName: "L",
            align: "left",
            sortable: true,
            width: 1,
            disableColumnMenu: true,
        },
        {
            field: "winPercentage",
            headerName: "%",
            align: "left",
            sortable: true,
            width: 1,
            disableColumnMenu: true,
        },
        {
            field: "pti",
            headerName: "PTI",
            align: "left",
            sortable: true,
            width: 56,
            disableColumnMenu: true,
            renderCell: (params) => {
                const row = params.row as PartnerRow;
                return <span>{formatTwoDecimalWithSign(row.pti)}</span>;
            },
        },
    ];

    const handleOnRowClick = (e: GridRowParams<PartnerRow>) => {
        const { row } = e;
        setSelectedPartnerRow(row);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Dialog open={open} onClose={handleClose} scroll="body">
                <DialogTitle>Partner Matches</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent sx={{ pl: 1, pr: 1 }}>
                    <MatchesTable
                        matches={filteredMatched.filter((match) => {
                            if (
                                selectedPartnerRow?.id &&
                                isPartner(
                                    match,
                                    playerId,
                                    selectedPartnerRow.id,
                                )
                            ) {
                                return true;
                            }

                            return false;
                        })}
                        playerId={playerId}
                    />
                </DialogContent>
            </Dialog>
            <Box style={{ marginBottom: 5 }} component="div">
                <AutoCompleteDateWrapper
                    label="search partner"
                    handleDaysFilter={setDaysAgo}
                    initialValues={autoCompleteInputs || []}
                    selectionOptions={rows}
                    handleSelectValues={setAutoCompleteInputs}
                    multiple
                />
            </Box>
            <BaseTable
                rows={rows.filter((row) => {
                    if (autoCompleteInputs && autoCompleteInputs.length > 0) {
                        return autoCompleteInputs
                            .map((input) => input.autoCompleteValue)
                            .includes(row.autoCompleteValue);
                    }

                    return true;
                })}
                cols={cols}
                initialSortField="partnerName"
                handleOnRowClick={handleOnRowClick}
            />
        </>
    );
}
