export function coerceSlider(val: number | number[]): number {
    return typeof val === "number" ? val : val[0];
}

// Clamp a number (possible encoded as a string or a number type) to make sure it doesn't go outside
// of a given min/max range.
export function clampValue(
    val: string | number,
    minMax: { min: number; max: number },
): number {
    const numVal = parseInt(val.toString(), 10);
    if (numVal < minMax.min) return minMax.min;
    if (numVal > minMax.max) return minMax.max;
    return numVal;
}

export function clampFloatValue(
    val: string | number,
    minMax: { min: number; max: number },
): number {
    const numVal = parseFloat(val.toString());
    if (numVal < minMax.min) return minMax.min;
    if (numVal > minMax.max) return minMax.max;
    return numVal;
}
