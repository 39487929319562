import * as React from "react";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Chip from "@mui/material/Chip";
import FormControl from "@mui/material/FormControl";
import Input from "@mui/material/Input";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";

import { AppId } from "../../apps";

interface AppFilter {
    appId: AppId;
    label: string;
}

const APP_TYPES: AppFilter[] = [
    {
        appId: 11,
        label: "Skill Building",
    },
    {
        appId: 9,
        label: "Multi-Level",
    },
    {
        appId: 5,
        label: "Single-Shot",
    },
    {
        appId: 4,
        label: "Multi-Shot",
    },
];

export interface PublisherSelectProps {
    selectedAppId: AppId | null;
    updateSelected: (updated: AppId | null) => void;
}

export default function PublisherSelect({
    selectedAppId,
    updateSelected,
}: PublisherSelectProps): JSX.Element {
    const [open, setOpen] = React.useState(false);
    return (
        <FormControl fullWidth>
            <Select
                id="demo-multiple-chip"
                placeholder="Publisher"
                fullWidth
                open={open}
                value={selectedAppId}
                onChange={(e) => {
                    updateSelected(e.target.value as AppId);
                    setOpen(false);
                }}
                IconComponent={KeyboardArrowDownIcon}
                input={
                    <Input
                        id="select-multiple-chip"
                        fullWidth
                        disableUnderline
                        placeholder="Publisher"
                        sx={{
                            backgroundColor: "white",
                            borderRadius: "20px",
                            padding: "2px",
                            minHeight: "45px",
                        }}
                        onClick={() => {
                            setOpen(!open);
                        }}
                    />
                }
                renderValue={(selected) => (
                    <Stack direction="row" spacing={1}>
                        {APP_TYPES.filter((p) => selected === p.appId).map(
                            (value) => (
                                <Chip
                                    key={value.appId}
                                    label={value.label}
                                    sx={{
                                        minWidth: "80px",
                                    }}
                                    onDelete={() => {
                                        updateSelected(null);
                                    }}
                                />
                            ),
                        )}
                    </Stack>
                )}
            >
                {APP_TYPES.map((p) => (
                    <MenuItem key={p.appId} value={p.appId}>
                        {p.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
