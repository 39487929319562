import * as React from "react";

import Button from "@mui/material/Button";
import Link from "@mui/material/Link";

import fetchApi, { logFetchError } from "../../util/fetchApi";

export default function BillingButton(): JSX.Element | null {
    const [billingUrl, setBillingUrl] = React.useState("");

    React.useEffect(() => {
        if (billingUrl) return;
        fetchApi<{ url: string }>("/api/users/billing-portal")
            .then(({ url }) => {
                setBillingUrl(url);
            })
            .catch((e) => logFetchError(e));
    }, [billingUrl]);

    return (
        <Button
            component={Link}
            variant="contained"
            size="large"
            fullWidth
            color="info"
            href={billingUrl}
            target="_blank"
            disabled={!billingUrl}
            rel="noopener"
        >
            Manage Billing
        </Button>
    );
}
