import * as React from "react";

import SwapVertIcon from "@mui/icons-material/SwapVert";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { useStatus } from "../../hooks/status";
import { LiftModal, useLift } from "../../hooks/useLift";

export default function Height(): JSX.Element {
    const {
        error,
        height,
        lowerHead,
        isLifting,
        liftRange,
        setHeight,
        stop,
        tickUp,
        tickDown,
    } = useLift();

    const { status } = useStatus();

    const [targetHeight, setTargetHeight] = React.useState(height.toFixed(1));
    const [errMsg, setErrMsg] = React.useState<string | null>(null);

    const moveDown = React.useCallback(async () => {
        setTargetHeight(liftRange.min.toFixed(1));
        await lowerHead();
    }, [setTargetHeight, lowerHead, liftRange]);

    const setPosition = React.useCallback(async () => {
        const parsed = parseFloat(targetHeight);
        if (Number.isNaN(parsed)) {
            setErrMsg("Not a valid number!");
        } else if (parsed >= liftRange.min && parsed <= liftRange.max) {
            await setHeight(parsed);
        } else {
            setErrMsg(
                `Height must be between ${liftRange.min} and ${liftRange.max} inches`,
            );
        }
    }, [setHeight, setErrMsg, liftRange, targetHeight]);

    const canLift = status?.trainer.lift.state === "Online";

    const faultMessage =
        status === null ||
        status.fault === null ||
        status.fault.failures.length === 0
            ? ""
            : status.fault.failures[0].message;

    return (
        <Grid item container direction="column" spacing={3}>
            {isLifting && (
                <LiftModal stop={stop} targetHeight={Number(targetHeight)} />
            )}
            {status?.fault !== null && (
                <Grid item>
                    <Box>
                        <Typography align="center" variant="h6">
                            {faultMessage}
                        </Typography>
                    </Box>
                </Grid>
            )}
            <Grid item>
                <Typography variant="h2" align="center">
                    Trainer Height
                </Typography>
            </Grid>
            <Grid item>
                <Typography align="center">
                    Current Height (inches):&nbsp;
                    {height.toFixed(1)}
                </Typography>
            </Grid>
            {canLift && (
                <>
                    <Grid item container>
                        <Grid
                            item
                            xs={3}
                            container
                            direction="column"
                            alignItems="center"
                        >
                            <Button
                                type="submit"
                                variant="contained"
                                sx={(theme) => ({
                                    backgroundColor:
                                        theme.palette.primary.light,
                                    borderWidth: "1px",
                                    borderColor: theme.palette.primary.light,
                                    borderRadius: "50%",
                                    width: "60px",
                                    height: "60px",
                                    color: theme.palette.common.white,
                                    "&:hover": {
                                        backgroundColor:
                                            theme.palette.primary.main,
                                    },
                                    fontSize: "25px",
                                    fontWeight: "bold",
                                    boxShadow: theme.shadows[2],
                                })}
                                disabled={isLifting}
                                onClick={async () =>
                                    setTargetHeight(
                                        (await tickDown()).toFixed(1),
                                    )
                                }
                            >
                                -
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Box
                                alignContent="center"
                                justifyContent="center"
                                display="flex"
                            >
                                <TextField
                                    label="New Height (inches)"
                                    name="value"
                                    value={targetHeight}
                                    variant="outlined"
                                    onChange={(evt) => {
                                        setTargetHeight(evt.target.value);
                                    }}
                                />
                            </Box>
                            {(error || errMsg) && (
                                <Alert severity="error">
                                    {error ?? errMsg}
                                </Alert>
                            )}
                        </Grid>
                        <Grid
                            item
                            xs={3}
                            container
                            direction="column"
                            alignItems="center"
                        >
                            <Button
                                type="submit"
                                variant="contained"
                                disabled={isLifting}
                                onClick={async () =>
                                    setTargetHeight((await tickUp()).toFixed(1))
                                }
                                sx={(theme) => ({
                                    backgroundColor:
                                        theme.palette.primary.light,
                                    borderWidth: "1px",
                                    borderColor: theme.palette.primary.light,
                                    borderRadius: "50%",
                                    width: "60px",
                                    height: "60px",
                                    color: theme.palette.common.white,
                                    "&:hover": {
                                        backgroundColor:
                                            theme.palette.primary.main,
                                    },
                                    fontSize: "25px",
                                    fontWeight: "bold",
                                    boxShadow: theme.shadows[2],
                                })}
                            >
                                +
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Button
                            fullWidth
                            type="submit"
                            sx={(theme) => ({
                                borderRadius: "30px",
                                height: "60px",
                                backgroundColor: theme.palette.primary.light,
                                borderWidth: "1px",
                                borderColor: theme.palette.primary.light,
                                color: theme.palette.common.white,
                                "&:hover": {
                                    backgroundColor: theme.palette.primary.main,
                                },
                                fontSize: "25px",
                                fontWeight: "bold",
                                boxShadow: theme.shadows[2],
                            })}
                            endIcon={<SwapVertIcon />}
                            disabled={isLifting}
                            onClick={() => setPosition()}
                        >
                            Move to New Height
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            fullWidth
                            type="submit"
                            endIcon={<SwapVertIcon />}
                            disabled={isLifting}
                            onClick={moveDown}
                            sx={(theme) => ({
                                borderRadius: "30px",
                                height: "60px",
                                backgroundColor: theme.palette.primary.light,
                                borderWidth: "1px",
                                borderColor: theme.palette.primary.light,
                                color: theme.palette.common.white,
                                "&:hover": {
                                    backgroundColor: theme.palette.primary.main,
                                },
                                fontSize: "25px",
                                fontWeight: "bold",
                                boxShadow: theme.shadows[2],
                            })}
                        >
                            Lower Head
                        </Button>
                    </Grid>
                </>
            )}
        </Grid>
    );
}
