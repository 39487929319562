import * as React from "react";

import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function TrainerIcon(props: SvgIconProps): JSX.Element {
    return (
        <SvgIcon viewBox="0 0 48 48" {...props}>
            <path d="M34,33.23v-6.07H14v6.07h4.87v7.85l-3.77,.8v2.12h17.79v-2.12l-3.79-.8v-7.85h4.9Zm-8.43-1.48h-3.14v-3.12h3.14v3.12Z" />
            <path d="M24,4c-4.49,0-10,2.38-10,3.57v11.81l4.1,3.97h11.79l4.1-3.97V7.57c0-1.19-5.51-3.57-10-3.57Zm3.8,9.68c0,2.1-1.7,3.8-3.8,3.8s-3.8-1.7-3.8-3.8,1.7-3.8,3.8-3.8,3.8,1.7,3.8,3.8Z" />
        </SvgIcon>
    );
}
