import * as React from "react";

import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";

function defaultValuetext(value: number, index: number): string {
    return `${value} at ${index}`;
}

function defaultValueLabelFormat(value: number): string {
    return `${value}`;
}

interface Props {
    minDistance: number;
    minValue: number;
    maxValue: number;
    handleChange?: (range: number[]) => void;
    valueLabelFormat?: (value: number) => string;
    valueText?: (value: number, index: number) => string;
}

export default function DoubleSlider({
    minDistance,
    minValue,
    maxValue,
    handleChange = () => {},
    valueLabelFormat = defaultValueLabelFormat,
    valueText = defaultValuetext,
}: Props) {
    const [value1, setValue1] = React.useState<number[]>([minValue, maxValue]);

    const handleSliderChange = (
        _event: Event,
        newValue: number | number[],
        activeThumb: number,
    ) => {
        if (!Array.isArray(newValue)) {
            return;
        }

        if (activeThumb === 0) {
            const range = [
                Math.min(newValue[0], value1[1] - minDistance),
                value1[1],
            ];
            setValue1(range);
            handleChange(range);
        } else {
            const range = [
                value1[0],
                Math.max(newValue[1], value1[0] + minDistance),
            ];
            setValue1(range);
            handleChange(range);
        }
    };

    return (
        <Box minWidth="250px" px={4}>
            <Slider
                disableSwap
                getAriaLabel={() => "Minimum distance"}
                getAriaValueText={valueText}
                onChange={handleSliderChange}
                max={maxValue}
                min={minValue}
                value={value1}
                valueLabelDisplay="auto"
                valueLabelFormat={valueLabelFormat}
            />
        </Box>
    );
}
