import * as React from "react";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Box from "@mui/material/Box/Box";
import Grid, { GridSize } from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Slider from "@mui/material/Slider";
import TextField from "@mui/material/TextField/TextField";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { useStatus } from "../../hooks/status";
import { clampValue, coerceSlider } from "../util";

const STEP_BY_RPM = 10;

type Orientation = "vertical" | "horizontal" | undefined;

interface Props {
    label: string;
    orientation?: Orientation;
    rpm: number;
    rpmVariation?: number;
    showLabelOnly?: boolean;
    setRpm: (rpm: number) => void;
    disabled: boolean;
}

function determineDirection(
    orientation: Orientation,
): "row" | "column-reverse" {
    return orientation === "vertical" ? "column-reverse" : "row";
}

export default function ShotRpmSlider({
    label,
    orientation = "horizontal",
    rpm,
    rpmVariation = 0,
    showLabelOnly = false,
    setRpm,
    disabled,
}: Props): JSX.Element {
    const theme = useTheme();
    const widerScreen = useMediaQuery(theme.breakpoints.up("md"));
    // Temporary change: hard-coded value for Physics modeling measurements
    const { status } = useStatus();
    const rpmMarks: React.MutableRefObject<{ value: number }[]> = React.useRef(
        [],
    );
    const minRpm = status?.trainer.wheels.top.range.min || 0;
    // const maxRpm = status?.trainer.wheels.top.range.max || 0;
    const maxRpm = 6000;
    React.useEffect(() => {
        const numMarks = (maxRpm - minRpm) / 10 + 1;
        rpmMarks.current = [{ value: 0 }].concat(
            [...Array(numMarks).keys()].map((curr) => ({
                value: minRpm + curr * STEP_BY_RPM,
            })),
        );
    }, [maxRpm, minRpm]);
    const buttonGridProps = {
        xs: orientation === "vertical" ? 12 : (3 as GridSize),
        md: orientation === "vertical" ? 12 : (1 as GridSize),
        style: {
            alignSelf: "center",
        },
    };

    const sliderGridProps = {
        xs: orientation === "vertical" ? 12 : (6 as GridSize),
        md: orientation === "vertical" ? 12 : (10 as GridSize),
        style: {
            alignSelf: "center",
        },
    };

    const marks = [
        { value: 0, label: "0 RPM" },
        { value: minRpm, label: `${minRpm} RPM` },
        { value: maxRpm, label: `${maxRpm} RPM` },
    ];

    if (rpmVariation && rpm >= minRpm) {
        const rpmUpper = rpm + rpmVariation;
        const rpmLower = rpm - rpmVariation;

        if (rpmLower > minRpm + 140) {
            marks.push({
                value: rpmLower,
                label: rpmVariation > 70 ? `${rpmLower} RPM` : "",
            });
        }

        if (rpmUpper < maxRpm - 250 && rpmUpper > minRpm + 140) {
            marks.push({
                value: rpmUpper,
                label: rpmVariation > 70 ? `${rpmUpper} RPM` : "",
            });
        }
    }

    const arrowButton = {
        border: "1px solid",
        margin: ".5em",
        "& .MuiSvgIcon-root": {
            width: "1.5em",
            height: "1.5em",
        },
    };

    return (
        <Grid container direction={determineDirection(orientation)}>
            <Grid item xs={12} style={{ textAlign: "center" }}>
                {widerScreen ? (
                    <Typography variant="h4">{`${rpm} RPM (${label})`}</Typography>
                ) : (
                    <Typography variant="h4">{`RPM (${label})`}</Typography>
                )}
            </Grid>
            {!showLabelOnly && (
                <>
                    <Grid item {...buttonGridProps}>
                        <IconButton
                            color="primary"
                            onClick={() =>
                                setRpm(rpm <= minRpm ? 0 : rpm - STEP_BY_RPM)
                            }
                            sx={arrowButton}
                            disabled={disabled || rpm <= 0}
                            size="large"
                        >
                            <RemoveIcon />
                        </IconButton>
                    </Grid>
                    <Grid item {...sliderGridProps}>
                        {widerScreen ? (
                            <Slider
                                sx={
                                    orientation === "vertical"
                                        ? {
                                              "&.MuiSlider-root": {
                                                  padding: "0 32px",
                                                  height: "97%",
                                              },
                                              "& .MuiSlider-thumb": {
                                                  height: 36,
                                                  width: 36,
                                                  marginBottom: -16,
                                                  marginLeft: -8,
                                                  "&.Mui-disabled": {
                                                      marginBottom: -16,
                                                      marginLeft: -8,
                                                  },
                                              },
                                              "& .MuiSlider-rail, &.MuiSlider-track":
                                                  {
                                                      width: 18,
                                                      height: 200,
                                                  },
                                          }
                                        : {
                                              "& .MuiSlider-thumb": {
                                                  backgroundColor:
                                                      theme.palette.primary
                                                          .main,
                                                  height: 36,
                                                  width: 36,
                                                  marginTop: -9,
                                                  marginLeft: -18,
                                              },
                                              "&.MuiSlider-root": {
                                                  padding: "35px 0",
                                                  width: "97%",
                                                  marginLeft: theme.spacing(2),
                                                  marginRight: theme.spacing(2),
                                              },
                                              "& .MuiSlider-rail, &.MuiSlider-track":
                                                  {
                                                      height: 18,
                                                  },
                                              "& .MuiSlider-markLabel": {
                                                  top: "15%",
                                              },
                                              "& .MuiSlider-mark": {
                                                  height: "32px",
                                                  backgroundColor:
                                                      theme.palette.secondary
                                                          .dark,
                                                  marginTop: "-8px",
                                              },
                                          }
                                }
                                value={rpm}
                                orientation={orientation}
                                onChange={(_, val) => setRpm(coerceSlider(val))}
                                min={0}
                                max={maxRpm}
                                step={STEP_BY_RPM}
                                marks={marks}
                                disabled={disabled}
                                color="secondary"
                            />
                        ) : (
                            <Box style={{ textAlign: "center" }}>
                                <TextField
                                    disabled={disabled}
                                    onChange={(evt) =>
                                        setRpm(parseInt(evt.target.value, 10))
                                    }
                                    onBlur={(evt) =>
                                        setRpm(
                                            clampValue(evt.target.value, {
                                                min: minRpm,
                                                max: maxRpm,
                                            }),
                                        )
                                    }
                                    onFocus={(evt) => evt.target.select()}
                                    type="number"
                                    style={{ width: "50%" }}
                                    value={rpm}
                                />
                            </Box>
                        )}
                    </Grid>
                    <Grid item {...buttonGridProps}>
                        <IconButton
                            color="primary"
                            onClick={() =>
                                setRpm(
                                    rpm < minRpm ? minRpm : rpm + STEP_BY_RPM,
                                )
                            }
                            sx={arrowButton}
                            disabled={disabled || rpm >= maxRpm}
                            size="large"
                        >
                            <AddIcon />
                        </IconButton>
                    </Grid>
                </>
            )}
        </Grid>
    );
}
