import * as React from "react";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent, { CardContentProps } from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

import type {
    ClubLocationStats,
    MatchUnified,
} from "@volley/data/src/types/pti";

import fetchApi, { logFetchError } from "../../util/fetchApi";
import { useCurrentUser } from "../hooks/currentUser";

import {
    getEndRating,
    getMatchesAsc,
    getMatchesDesc,
    getRecord,
    transformToCoordinates,
} from "./utils/util";

interface Props {
    matches: MatchUnified[];
    playerId: number;
}

const SummaryCardContent = styled(CardContent)<CardContentProps>(
    ({ theme }) => ({
        padding: theme.spacing(1),
        "&:last-child": {
            padding: theme.spacing(1),
        },
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
    }),
);

export default function PlayerPTISummary({
    matches,
    playerId,
}: Props): JSX.Element {
    const { currentUser } = useCurrentUser();

    const pti = React.useMemo(
        () =>
            getEndRating(
                transformToCoordinates(getMatchesDesc(matches), playerId),
            ),
        [matches, playerId],
    );
    const record = React.useMemo(
        () => getRecord(matches, playerId),
        [matches, playerId],
    );

    const [bestLocation, setBestLocation] =
        React.useState<ClubLocationStats | null>(null);
    React.useEffect(() => {
        fetchApi<ClubLocationStats[]>("/api/pti/locations-stats", "GET")
            .then((data) => {
                let maxWins = 0;
                let maxWinsIndex = 0;
                data.forEach((l, i) => {
                    if (l.wins > maxWins) {
                        maxWins = l.wins;
                        maxWinsIndex = i;
                    }
                });
                setBestLocation(data[maxWinsIndex]);
            })
            .catch((error) => logFetchError(error));
    }, []);

    const firstMatch = React.useMemo(
        () => getMatchesAsc(matches)[0],
        [matches],
    );

    return (
        <Stack spacing={4} backgroundColor="primary.main" p={2}>
            <Stack
                direction="row"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
            >
                <Box component="div">
                    <Typography
                        color="secondary.main"
                        variant="h3"
                        component="p"
                    >
                        {currentUser?.firstName ?? ""}
                    </Typography>
                    <Typography
                        color="secondary.main"
                        variant="h3"
                        component="p"
                    >
                        {currentUser?.lastName ?? ""}
                    </Typography>
                </Box>
                <Box
                    component="div"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="space-around"
                    borderRadius="50%"
                    backgroundColor="#ffffff"
                    width={120}
                    height={120}
                >
                    <Typography variant="h2" component="p" pt={4}>
                        {pti}
                    </Typography>
                    <Typography>PTI</Typography>
                </Box>
            </Stack>
            <Stack
                direction="row"
                display="flex"
                flex={2}
                alignItems="center"
                justifyContent="space-between"
                spacing={4}
            >
                <Card sx={{ flexGrow: 1 }}>
                    <SummaryCardContent sx={{ p: 0 }}>
                        <Typography variant="h1" component="p">
                            {record.wins}
                        </Typography>
                        <Typography>Wins</Typography>
                    </SummaryCardContent>
                </Card>
                <Card sx={{ flexGrow: 1 }}>
                    <SummaryCardContent>
                        <Typography variant="h1" component="p">
                            {record.losses}
                        </Typography>
                        <Typography>Losses</Typography>
                    </SummaryCardContent>
                </Card>
            </Stack>
            {bestLocation && (
                <Card>
                    <SummaryCardContent>
                        <Typography variant="h4" component="p">
                            {`${bestLocation.club_name}: ${bestLocation.wins}`}
                        </Typography>
                        <Typography>Location with most wins</Typography>
                    </SummaryCardContent>
                </Card>
            )}
            <Card>
                <SummaryCardContent>
                    <Typography variant="h2" component="p">
                        {firstMatch
                            ? new Date(firstMatch.date).toDateString()
                            : ""}
                    </Typography>
                    <Typography>Playing since</Typography>
                </SummaryCardContent>
            </Card>
        </Stack>
    );
}
