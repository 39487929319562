import * as React from "react";
import { Route, Routes } from "react-router-dom";

import EditPlugin from "./EditPlugin";
import PlayPlugin from "./PlayPlugin";
import ResponsivePlayApp from "./ResponsivePlayApp";
import WorkoutList from "./WorkoutList";
import AimSpinPlay from "./apps/freeplay/AimSpinPlay";
import LobApp from "./apps/lobs/LobApp2";
import PickleballGame from "./apps/pickball-game/pickleBallGame";
import ServeAndVolley from "./apps/serve-and-volley/serveAndVolley";

export default function AppWorkouts(): JSX.Element {
    return (
        <Routes>
            <Route path="plugin">
                <Route path="play/:appId/:id?/*" element={<PlayPlugin />} />
                <Route path="edit/:appId/:id?" element={<EditPlugin />} />
            </Route>
            <Route
                path="search"
                element={<WorkoutList fetchUrl="/api/app-workouts/search" />}
            />
            <Route
                path="favorites"
                element={
                    <WorkoutList fetchUrl="/api/app-workouts/favorites/mine" />
                }
            />
            <Route path="user">
                <Route
                    path=""
                    element={
                        <WorkoutList
                            fetchUrl="/api/app-workouts/mine"
                            groupByType
                        />
                    }
                />
            </Route>
            <Route path="experimental">
                <Route path="lobapp" element={<LobApp />} />
                <Route path="responsive" element={<ResponsivePlayApp />} />
                <Route path="pickleball" element={<PickleballGame />} />
                <Route path="serveandvolley" element={<ServeAndVolley />} />
            </Route>
            <Route path="freeplay/:id?" element={<AimSpinPlay />} />
        </Routes>
    );
}
