import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import CancelIcon from "@mui/icons-material/Cancel";
import IosShareIcon from "@mui/icons-material/IosShare";
import RefreshIcon from "@mui/icons-material/Refresh";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { TableCellProps } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import type { Invite, SessionForUserHistory } from "@volley/data";
import { AppWorkout, User } from "@volley/data";

import logger from "../../log";
import fetchApi, { logFetchError } from "../../util/fetchApi";
import { useSelectedSport } from "../common/context/sport";
import { useCurrentUser } from "../hooks/currentUser";

import ExpandableList from "./ExpandableList";

const DEFAULT_PAGE_SIZE = 10;

type InviteWithCreatedFor = Invite & { createdFor: string[] };

interface WorkoutSummary {
    id: number;
    appId: number;
    originalAppId?: number;
    sportId: number;
    name: string;
    startTime: string;
    timePlayed: number;
    throwCount: number;
    canPlay: boolean;
}

interface SharedWithUser {
    id: number;
    name: string;
}

export interface HistoryCardData {
    id: number;
    startTime: string;
    duration: number;
    workouts: WorkoutSummary[];
    showShareButton: boolean;
    sharedBy?: {
        name: string;
    };
    sharedWith?: SharedWithUser[];
}

export interface HistoryCardProps extends HistoryCardData {
    pending: string[];
    scrollToId: number | null;
    shareOpen: number | null;
    setShareOpen: (sessionId: number | null) => void;
}

function TightTableCell(props: TableCellProps): JSX.Element {
    const { children, sx, ...rest } = props;
    const defaults = {
        fontSize: "0.75rem",
        color: "primary.main",
    };
    return (
        <TableCell
            sx={{
                ...defaults,
                ...sx,
            }}
            {...rest}
            align="right"
            padding="none"
        >
            {children}
        </TableCell>
    );
}

interface HistoryRowProps {
    workout: WorkoutSummary;
    showCheckboxes: boolean;
    checked: boolean;
    canCheck: boolean;
    onCheckClick: () => void;
}

async function getRedirectUrl(
    appId: number,
    workoutId: number,
): Promise<string | null> {
    let updatedAppId = appId;
    let updatedWorkoutId = workoutId;
    if (appId === 7) {
        try {
            updatedAppId = await fetchApi<number>(
                `/api/app-workouts/original/${workoutId}`,
            );
        } catch (e) {
            logFetchError(
                e,
                `Failed to get original app id for shared workout ${workoutId}`,
            );
            return null;
        }
    }
    if (appId === 4 || appId === 6) {
        // these apps were migrated to the leveled app (9)
        updatedAppId = 9;
        try {
            updatedWorkoutId = await fetchApi<number>(
                `/api/app-workouts/migrated/${workoutId}`,
            );
        } catch (e) {
            logFetchError(
                e,
                `Failed to get migrated workout id for ${workoutId}`,
            );
            return null;
        }
    }
    return `/content/apps/workouts/plugin/play/${updatedAppId}/${updatedWorkoutId}`;
}

export function HistoryRow({
    workout,
    showCheckboxes,
    checked,
    canCheck,
    onCheckClick,
}: HistoryRowProps): JSX.Element {
    const navigate = useNavigate();
    const {
        selected,
        getIdFromSport,
        getSportFromId,
        getFriendlyName,
        updateSelected,
    } = useSelectedSport();
    const [dialogText, setDialogText] = React.useState("");
    const [showDialog, setShowDialog] = React.useState(false);
    const [showCantPlay, setShowCantPlay] = React.useState(false);

    const ttcSx = React.useMemo(
        () => ({
            width: showCheckboxes ? "23%" : "25%",
        }),
        [showCheckboxes],
    );

    const handleWorkoutClick = React.useCallback(async () => {
        if (workout.canPlay) {
            const selectedSportId = getIdFromSport(selected);
            if (workout.sportId !== selectedSportId) {
                const wType = getFriendlyName(workout.sportId);
                const currentType = getFriendlyName(selected);
                const text = `This workout is a ${wType} workout, but you're current selected sport is ${currentType}`;
                setDialogText(text);
                setShowDialog(true);
            } else {
                const url = await getRedirectUrl(workout.appId, workout.id);
                if (url) {
                    navigate(url);
                } else {
                    setShowCantPlay(true);
                }
            }
        }
    }, [workout, selected, getIdFromSport, getFriendlyName, navigate]);

    const workoutSport = React.useMemo(
        () => getSportFromId(workout.sportId),
        [workout, getSportFromId],
    );

    const handleChange = React.useCallback(async () => {
        updateSelected(workoutSport);
        const url = await getRedirectUrl(workout.appId, workout.id);
        if (url) {
            navigate(url);
        } else {
            setShowCantPlay(true);
        }
    }, [updateSelected, workoutSport, navigate, workout.appId, workout.id]);

    return (
        <TableRow>
            {showCheckboxes && (
                <TightTableCell
                    sx={{
                        width: "8%",
                    }}
                >
                    <Checkbox
                        sx={{
                            padding: 0,
                        }}
                        disabled={!canCheck}
                        checked={checked}
                        onClick={() => onCheckClick()}
                    />
                </TightTableCell>
            )}
            <TightTableCell sx={ttcSx} onClick={() => handleWorkoutClick()}>
                <Typography
                    variant="body2"
                    sx={{
                        color: workout.canPlay ? "info.main" : "inherit",
                    }}
                >
                    {workout.name}
                </Typography>
            </TightTableCell>
            <TightTableCell sx={ttcSx}>{workout.startTime}</TightTableCell>
            <TightTableCell
                sx={ttcSx}
            >{`${workout.timePlayed} min`}</TightTableCell>
            <TightTableCell sx={ttcSx}>{workout.throwCount}</TightTableCell>
            <Dialog open={showDialog}>
                <DialogTitle>Change Sport</DialogTitle>
                <DialogContent>{dialogText}</DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowDialog(false)}>Cancel</Button>
                    <Button
                        onClick={() => handleChange()}
                        variant="contained"
                        color="primary"
                    >
                        Change
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={showCantPlay}>
                <DialogTitle>Unable to Play</DialogTitle>
                <DialogContent>
                    Sorry, we&apos;re unable to load that workout.
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setShowCantPlay(false)}
                        variant="contained"
                        color="primary"
                    >
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </TableRow>
    );
}

export function HistoryCard({
    id,
    startTime,
    duration,
    pending,
    showShareButton,
    sharedBy,
    sharedWith,
    scrollToId,
    workouts,
    shareOpen,
    setShareOpen,
}: HistoryCardProps): JSX.Element {
    const navigate = useNavigate();
    const shareable = React.useMemo(
        () => workouts.filter((w) => w.canPlay),
        [workouts],
    );
    const [toShare, setToShare] = React.useState<WorkoutSummary[]>(shareable);
    const showToggle = showShareButton && !sharedBy;
    const showCheckboxes = shareOpen === id;
    const headers = ["Workout", "Start Time", "Time Played", "Throw Count"];

    const scrollRef = React.useRef<HTMLDivElement | null>(null);
    function executeScroll() {
        logger.info("Execute Scroll Called");
        if (scrollRef.current) {
            logger.info(
                `Attempting to scroll to element: ${scrollRef.current ? scrollRef.current.offsetTop : "none"}`,
            );
            scrollRef.current.scrollIntoView();
        } else {
            logger.info("scrollRef.current was null");
        }
    }

    const tableRows = React.useMemo(
        () =>
            workouts.map((w) => (
                <HistoryRow
                    checked={toShare.find((i) => i.id === w.id) !== undefined}
                    canCheck={w.canPlay}
                    onCheckClick={() => {
                        if (toShare.find((i) => i.id === w.id)) {
                            setToShare(toShare.filter((i) => i.id !== w.id));
                        } else {
                            const updated = [...toShare];
                            updated.push(w);
                            setToShare(updated);
                        }
                    }}
                    showCheckboxes={showCheckboxes}
                    workout={w}
                    key={`${w.id}-${w.startTime}`}
                />
            )),
        [workouts, toShare, showCheckboxes],
    );

    React.useEffect(() => {
        if (scrollToId === id) {
            executeScroll();
        }
    }, [id, scrollToId]);

    return (
        <Card
            elevation={6}
            ref={scrollRef}
            sx={{
                padding: "5px",
                border: sharedBy ? "3px #096EE5 solid" : "none",
            }}
        >
            <Stack spacing={1}>
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="h3" color="primary.main">
                        {startTime}
                    </Typography>
                    {showToggle && shareOpen !== id && (
                        <Button
                            onClick={() => setShareOpen(id)}
                            size="small"
                            color="secondary"
                            variant="contained"
                        >
                            Share
                        </Button>
                    )}
                    {showToggle && shareOpen === id && (
                        <IconButton
                            onClick={() => setShareOpen(null)}
                            size="small"
                            color="secondary"
                        >
                            {shareOpen === id && <CancelIcon />}
                            {shareOpen !== id && <IosShareIcon />}
                        </IconButton>
                    )}
                </Stack>
                <Typography
                    variant="h5"
                    color="primary.main"
                >{`Duration: ${duration} min`}</Typography>
                {sharedBy && (
                    <Stack direction="row">
                        <Typography variant="h5" color="primary.main">
                            Shared By:&nbsp;
                        </Typography>
                        <Typography variant="h5" color="info.main">
                            {sharedBy.name}
                        </Typography>
                    </Stack>
                )}
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {showCheckboxes && (
                                    <TightTableCell>
                                        <Checkbox
                                            sx={{
                                                padding: 0,
                                            }}
                                            checked={
                                                toShare.length ===
                                                shareable.length
                                            }
                                            onClick={() => {
                                                if (
                                                    toShare.length <
                                                    shareable.length
                                                ) {
                                                    setToShare(shareable);
                                                } else {
                                                    setToShare([]);
                                                }
                                            }}
                                        />
                                    </TightTableCell>
                                )}
                                {headers.map((h) => (
                                    <TightTableCell key={h}>{h}</TightTableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>{tableRows}</TableBody>
                    </Table>
                </TableContainer>
                {showCheckboxes && (
                    <Button
                        color="secondary"
                        variant="contained"
                        fullWidth
                        disabled={toShare.length === 0}
                        onClick={() => {
                            const url = `/content/share?session=${id}&workouts=${toShare.map((w) => w.id).join(",")}`;
                            navigate(url);
                        }}
                    >
                        Share Workouts
                    </Button>
                )}
                {showToggle && (
                    <Stack>
                        {sharedWith && sharedWith.length > 0 && (
                            <ExpandableList
                                maxVisible={2}
                                prefix="Shared With"
                                values={sharedWith.map((s) => s.name)}
                            />
                        )}
                        {pending.length > 0 && (
                            <ExpandableList
                                maxVisible={0}
                                prefix="Invite Pending"
                                values={pending}
                            />
                        )}
                    </Stack>
                )}
            </Stack>
        </Card>
    );
}

export interface HistoryProps {
    fetchUrl: string;
    showShareButton?: boolean;
}

function canPlayWorkout(workout: AppWorkout, user: User | null) {
    if (!user) {
        return false;
    }

    switch (workout.appId) {
        case 1:
            return true;
        case 2:
            return false;
        case 4:
            return workout.state !== "SOFT_DELETE";
        case 5:
            return workout.state !== "SOFT_DELETE";
        case 6:
            return (
                workout.state !== "SOFT_DELETE" &&
                (workout.createdBy === user.email ||
                    workout.contentProviderId !== null)
            );
        case 7:
            return workout.state !== "SOFT_DELETE";
        case 9:
            return workout.state !== "SOFT_DELETE";
        default:
            return false;
    }
}

export default function History({
    fetchUrl,
    showShareButton = true,
}: HistoryProps): JSX.Element {
    const [hasFetched, setHasFetched] = React.useState(false);
    const [pending, setPending] = React.useState<InviteWithCreatedFor[]>([]);
    const [loading, setLoading] = React.useState(false);
    const [userHistory, setUserHistory] = React.useState<HistoryCardData[]>([]);
    const [cursorAfter, setCursorAfter] = React.useState<number | null>(null);
    const [hasMore, setHasMore] = React.useState(true);
    const { currentUser } = useCurrentUser();
    const [currentShareOpen, setCurrentShareOpen] = React.useState<
        number | null
    >(null);
    const location = useLocation();

    const [scrollToId, setScrollToId] = React.useState<number | null>(null);

    let openCurrent = false;
    if (location.state) {
        openCurrent =
            (location.state as { showCurrent: boolean } | undefined)
                ?.showCurrent ?? false;
    }

    React.useEffect(() => {
        if (userHistory.length > 0 && openCurrent) {
            if (userHistory[0].duration === 0) {
                setCurrentShareOpen(userHistory[0].id);
            }
        }
    }, [userHistory, openCurrent]);

    const fetchPendingInvites = React.useCallback(async () => {
        const response = await fetchApi<InviteWithCreatedFor[]>(
            "/api/invites/pending",
        );
        return response;
    }, []);

    const fetchHistory = React.useCallback(
        async (after: number | null) => {
            const base = `${fetchUrl}?count=${DEFAULT_PAGE_SIZE}`;
            let url = base;
            if (after) {
                url = `${base}&after=${after}`;
            }

            const response = await fetchApi<SessionForUserHistory[]>(url);

            const parsed = response.reverse().map((s) => {
                let duration = 0;
                const startDateTime = new Date(s.startTime);
                if (s.endTime) {
                    const endDateTime = new Date(s.endTime);
                    duration = Math.round(
                        (endDateTime.getTime() - startDateTime.getTime()) /
                            1000 /
                            60,
                    );
                }

                const isSessionUser = s.sessionUsers.find(
                    (su) => su.userId === currentUser?.id,
                );
                const sharedBy = !isSessionUser
                    ? { name: s.sessionUsers[0].user.username }
                    : undefined;

                const sharedWith: SharedWithUser[] = [];
                if (isSessionUser) {
                    const sharedWithDups = s.SharedWorkouts.map((sw) =>
                        sw.sharedWithUsers.map((swu) => ({
                            id: swu.sharedUserId,
                            name: swu.sharedUser.username,
                        })),
                    ).flat(1);

                    const seen: { id: number; name: string }[] = [];
                    sharedWithDups.forEach((shared) => {
                        if (!seen.find((i) => i.id === shared.id)) {
                            seen.push(shared);
                            sharedWith.push(shared);
                        }
                    });
                }

                let full: WorkoutSummary[] = [];
                if (isSessionUser) {
                    full = s.WorkoutPlay.map((wp) => {
                        let workoutDuration = 0;
                        const workoutStart = new Date(wp.startTime);
                        if (wp.endTime) {
                            const workoutEnd = new Date(wp.endTime);
                            workoutDuration = Math.round(
                                (workoutEnd.getTime() -
                                    workoutStart.getTime()) /
                                    1000 /
                                    60,
                            );
                        }
                        const startTime = workoutStart.toLocaleTimeString();
                        const summary: WorkoutSummary = {
                            appId: wp.workout.appId,
                            id: wp.workoutId,
                            name: wp.workout.name,
                            sportId: wp.workout.sportId,
                            startTime,

                            throwCount: wp._count.WorkoutThrow,
                            timePlayed: workoutDuration,
                            canPlay: canPlayWorkout(wp.workout, currentUser),
                        };
                        return summary;
                    });
                } else {
                    full = s.WorkoutPlay.filter(
                        (wp) =>
                            s.SharedWorkouts.find(
                                (sw) => sw.originalWorkoutId === wp.workoutId,
                            ) !== undefined,
                    ).map((wp) => {
                        let workoutDuration = 0;
                        const shared = s.SharedWorkouts.find(
                            (sw) => sw.originalWorkoutId === wp.workoutId,
                        );
                        const workoutStart = wp
                            ? new Date(wp.startTime)
                            : undefined;
                        if (wp?.endTime && workoutStart) {
                            const workoutEnd = new Date(wp.endTime);
                            workoutDuration = Math.round(
                                (workoutEnd.getTime() -
                                    workoutStart.getTime()) /
                                    1000 /
                                    60,
                            );
                        }
                        const startTime =
                            workoutStart?.toLocaleTimeString() || "Unknown";
                        const summary: WorkoutSummary = {
                            appId: shared!.originalWorkout.appId,
                            id: shared!.appWorkoutId,
                            name: shared!.workout.name,
                            sportId: shared!.workout.sport.id,
                            startTime,

                            throwCount: wp?._count.WorkoutThrow || 0,
                            timePlayed: workoutDuration,
                            originalAppId: wp?.workout.appId,
                            canPlay: canPlayWorkout(wp.workout, currentUser),
                        };
                        return summary;
                    });
                }

                const workouts: WorkoutSummary[] = full.reduce(
                    (prev: WorkoutSummary[], cur: WorkoutSummary) => {
                        if (prev.length === 0) {
                            prev.push(cur);
                            return prev;
                        }

                        const pw = prev[prev.length - 1];
                        if (pw.id === cur.id) {
                            const updated = [...prev];
                            updated[updated.length - 1].timePlayed +=
                                cur.timePlayed;
                            updated[updated.length - 1].throwCount +=
                                cur.throwCount;
                            return updated;
                        }
                        prev.push(cur);
                        return prev;
                    },
                    [] as WorkoutSummary[],
                );

                const converted: HistoryCardData = {
                    id: s.id,
                    startTime: startDateTime.toLocaleString("en-US", {
                        hour12: true,
                    }),
                    duration,
                    workouts,
                    showShareButton:
                        showShareButton === undefined ? true : showShareButton,
                    sharedBy,
                    sharedWith,
                };
                return converted;
            });
            return parsed.filter((p) => p.workouts.length > 0);
        },
        [currentUser, fetchUrl, showShareButton],
    );

    const refreshHistory = React.useCallback(() => {
        setLoading(true);
        fetchHistory(null)
            .then((result) => {
                if (result.length) {
                    setUserHistory(result);
                    setCursorAfter(result[result.length - 1].id);
                    if (result.length < DEFAULT_PAGE_SIZE) {
                        setHasMore(false);
                    }
                } else {
                    setUserHistory([]);
                    setCursorAfter(null);
                    setHasMore(false);
                    setScrollToId(null);
                }
            })
            .catch((e) => logFetchError(e))
            .finally(() => setLoading(false));
    }, [fetchHistory]);

    const loadMore = React.useCallback(() => {
        setLoading(true);
        fetchHistory(cursorAfter)
            .then((result) => {
                if (result.length) {
                    const s = result[0].id;
                    setScrollToId(s);
                    const fullHistory = userHistory.concat(result);
                    setUserHistory(fullHistory);
                    setCursorAfter(result[result.length - 1].id);
                    if (result.length < DEFAULT_PAGE_SIZE) {
                        setHasMore(false);
                        setCursorAfter(null);
                    } else {
                        setHasMore(true);
                    }
                } else {
                    setHasMore(false);
                    setCursorAfter(null);
                    const s = userHistory[userHistory.length - 1].id;
                    setScrollToId(s);
                }
            })
            .catch((e) => logFetchError(e))
            .finally(() => {
                setLoading(false);
            });
    }, [cursorAfter, userHistory, fetchHistory]);

    React.useEffect(() => {
        if (!hasFetched) {
            setHasFetched(true);
            setLoading(true);
            fetchPendingInvites()
                .then((p) => {
                    setPending(p);
                    return fetchHistory(null);
                })
                .then((result) => {
                    if (result.length) {
                        setUserHistory(result);
                        setCursorAfter(result[result.length - 1].id);
                        if (result.length < DEFAULT_PAGE_SIZE) {
                            setHasMore(false);
                        }
                    }
                })
                .catch((e) => logFetchError(e))
                .finally(() => setLoading(false));
        }
    }, [hasFetched, fetchHistory, fetchPendingInvites]);

    if (loading) {
        return <CircularProgress />;
    }

    return (
        <Stack spacing={1}>
            <Stack direction="row" justifyContent="space-between">
                <Typography
                    variant="h2"
                    color="primary.contrastText"
                    sx={{ paddingBottom: 2, fontSize: "1.5rem" }}
                >
                    My History
                </Typography>
                <IconButton color="primary" onClick={() => refreshHistory()}>
                    <RefreshIcon />
                </IconButton>
            </Stack>
            {userHistory.length === 0 && (
                <Typography>
                    No session history or shared workouts yet...
                </Typography>
            )}
            {userHistory.length > 0 &&
                userHistory.map((h) => {
                    const match = pending.find((p) => p.sessionId === h.id);
                    const p = match ? match.createdFor : [];
                    return (
                        <HistoryCard
                            key={h.id}
                            {...h}
                            scrollToId={scrollToId}
                            shareOpen={currentShareOpen}
                            setShareOpen={(val) => setCurrentShareOpen(val)}
                            pending={p}
                        />
                    );
                })}
            {userHistory.length > 0 && (
                <LoadingButton
                    variant="contained"
                    color="secondary"
                    fullWidth
                    loading={loading}
                    disabled={!hasMore}
                    onClick={() => loadMore()}
                >
                    Load More History
                </LoadingButton>
            )}
        </Stack>
    );
}
