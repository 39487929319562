import * as React from "react";

import ErrorIcon from "@mui/icons-material/Error";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Slider from "@mui/material/Slider";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";

import type { ProximityDetectionConfig } from "@volley/shared/vision-models";

import { logFetchError, pairedFetchApi } from "../../../util/fetchApi";
import NotchedOutline from "../../common/NotchedOutline";
import PlayerIcon from "../../common/icons/PlayerIcon";
import TrainerIcon from "../../common/icons/TrainerIcon";
import { useStatus } from "../../hooks/status";
import usePrevious from "../../hooks/usePrevious";
import useDialog from "../useDialog";

const distanceValues = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
const markedDistances = [1, 3, 5, 8, 10];

const distanceMarks = distanceValues.map((value) => ({
    value,
    label: markedDistances.includes(value) ? `${value} ft.` : undefined,
}));

const sensitivityValues = [1, 2, 3, 4, 5];

const sensitivityValueToLabel = (value: number): string => {
    switch (value) {
        case 1:
            return "Minimum";
        case 3:
            return "Moderate";
        case 5:
            return "Maximum";
        default:
            return "";
    }
};

const sensitivityMarks = sensitivityValues.map((value) => ({
    value,
    label: sensitivityValueToLabel(value),
}));

const defaultFormState: ProximityDetectionConfig = {
    enabled: false,
    sensitivity: 3,
    minDistance: 5,
};

type Action =
    | { type: "set"; value: ProximityDetectionConfig }
    | { type: "sensitivity"; value: number }
    | { type: "minDistance"; value: number }
    | { type: "enabled"; value: boolean };

function reducer(
    state: ProximityDetectionConfig,
    action: Action,
): ProximityDetectionConfig {
    switch (action.type) {
        case "enabled":
            return { ...state, enabled: action.value };
        case "sensitivity":
            return { ...state, sensitivity: action.value };
        case "minDistance":
            return { ...state, minDistance: action.value };
        case "set":
            return { ...action.value };
        default:
            return state;
    }
}

// used to compare prev state with current state for POSTing to API
// ony three properties so just doing inline like this but if this gets
// much more complicated a dedicated deep-equals or similar could be appropriate
function equals(
    a: ProximityDetectionConfig,
    b: ProximityDetectionConfig,
): boolean {
    return (
        a.enabled === b.enabled &&
        a.minDistance === b.minDistance &&
        a.sensitivity === b.sensitivity
    );
}

export default function ProximityConfig(): JSX.Element {
    const { setIgnoreProximity, ignoreProximity } = useDialog();
    const { status } = useStatus();

    const [loading, setLoading] = React.useState(true);
    const [initialFetch, setInitialFetch] = React.useState(false);
    const [formState, dispatch] = React.useReducer(reducer, defaultFormState);

    const previousFormState = usePrevious(formState);

    React.useEffect(() => {
        async function fetchProximityConfig() {
            const config = await pairedFetchApi<ProximityDetectionConfig>(
                status?.clientId,
                "/api/proximity/config",
            );
            setLoading(false);
            dispatch({ type: "set", value: config });
        }

        if (!initialFetch) {
            setInitialFetch(true);
            void fetchProximityConfig();
        }
    }, [status?.clientId, initialFetch]);

    // update on value changes once we've done first fetch
    React.useEffect(() => {
        if (
            initialFetch &&
            previousFormState &&
            !equals(previousFormState, formState)
        ) {
            setLoading(true);
            pairedFetchApi(
                status?.clientId,
                "/api/proximity/config",
                "POST",
                formState,
            )
                .catch((e) => logFetchError(e))
                .finally(() => setLoading(false));
        }
    }, [formState, initialFetch, status?.clientId, previousFormState]);

    return (
        <Grid container spacing={3}>
            <Grid item>
                <Typography variant="h4" color="primary.main">
                    Safety Mode
                </Typography>
            </Grid>
            <Grid item sx={{ width: "100%" }}>
                <NotchedOutline>
                    <Stack direction="row" alignItems="center" p={1}>
                        <Typography variant="h4" color="primary.main">
                            Enable safety mode:
                        </Typography>
                        <Stack
                            direction="row"
                            alignItems="center"
                            sx={{ ml: "auto" }}
                        >
                            <Typography>Off</Typography>
                            <Switch
                                disabled={loading}
                                checked={formState.enabled}
                                color="info"
                                onChange={(e) =>
                                    dispatch({
                                        type: "enabled",
                                        value: e.target.checked,
                                    })
                                }
                            />
                            <Typography>On</Typography>
                        </Stack>
                    </Stack>
                </NotchedOutline>
            </Grid>
            <Grid item>
                <Typography>
                    Set the distance safety mode will trigger when a person
                    moves into frame. Safety mode will be more accurate for
                    shorter distances.
                </Typography>
            </Grid>
            <Grid item container sx={{ width: "100%" }}>
                <Grid item>
                    <TrainerIcon sx={{ fontSize: 32 }} color="primary" />
                </Grid>
                <Grid item sx={{ ml: "auto" }}>
                    <PlayerIcon sx={{ fontSize: 32 }} color="primary" />
                </Grid>
            </Grid>
            <Grid item sx={{ width: "100%" }}>
                <Slider
                    value={formState.minDistance}
                    color="primary"
                    onChangeCommitted={(_, value) =>
                        dispatch({
                            type: "minDistance",
                            value: value as number,
                        })
                    }
                    disabled={loading}
                    valueLabelDisplay="auto"
                    step={1}
                    marks={distanceMarks}
                    min={1}
                    max={10}
                />
            </Grid>
            <Grid item sx={{ width: "100%" }}>
                <NotchedOutline label="Sensitivity">
                    <Box sx={{ px: 4 }}>
                        <Slider
                            value={formState.sensitivity}
                            color="primary"
                            onChangeCommitted={(_, value) =>
                                dispatch({
                                    type: "sensitivity",
                                    value: value as number,
                                })
                            }
                            disabled={loading}
                            valueLabelDisplay="auto"
                            step={1}
                            marks={sensitivityMarks}
                            min={1}
                            max={5}
                        />
                    </Box>
                </NotchedOutline>
            </Grid>
            {ignoreProximity && (
                <Grid item>
                    <Typography sx={{ mb: 1 }} color="error.main">
                        <ErrorIcon
                            color="error"
                            fontSize="small"
                            sx={{ mr: 1 }}
                        />
                        You&apos;re currently ignoring Safety Mode alerts
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setIgnoreProximity(false)}
                    >
                        Unignore Alerts
                    </Button>
                </Grid>
            )}
        </Grid>
    );
}
