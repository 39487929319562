import { MatchUnified } from "@volley/data";

import { Coordinates, MatchSubsetByPlayer } from "../types";

import { getPlayerSide, getOpponents, getWinner } from "./labels";

export function getDateByUtc(date: string): Date {
    let dateWithZeroOffset: string;

    if (date.endsWith("Z")) {
        dateWithZeroOffset = date;
    } else {
        dateWithZeroOffset = `${date}Z`;
    }

    return new Date(dateWithZeroOffset);
}

export function isNotNullOrUndefined(
    value: number | string | null | undefined,
): boolean {
    return value !== null && value !== undefined;
}

export function buildMatchKey(match: MatchUnified) {
    return `${match.date}-${match.schedule_id}-${match.line_id}`;
}

export function formatTwoDecimal(number: number): number {
    return Math.round(number * 100) / 100;
}

export function formatNumberSign(number: number) {
    if (number > 0) {
        return `+${number}`;
    }

    return `${number}`;
}

export function formatTwoDecimalWithSign(number: number) {
    return formatNumberSign(formatTwoDecimal(number));
}

export function withinTimeFilter(match: MatchUnified, daysAgo: number) {
    if (!daysAgo) {
        return true;
    }

    const matchDate = new Date(getDateByUtc(match.date));
    const date = new Date();

    date.setDate(date.getDate() - daysAgo);

    if (matchDate >= date) {
        return true;
    }

    return false;
}

export function getMatchesAsc(matches: MatchUnified[]) {
    return [...matches].sort((match1, match2) => {
        const date1 = new Date(match1.date);
        const date2 = new Date(match2.date);
        if (date1 <= date2) {
            return -1;
        }
        return 1;
    });
}

export function getMatchesDesc(matches: MatchUnified[]) {
    return [...matches].sort((match1, match2) => {
        const date1 = new Date(match1.date);
        const date2 = new Date(match2.date);
        if (date1 <= date2) {
            return 1;
        }
        return -1;
    });
}
// assumes ascending order

export function roundFirstDecimalPlace(rating: number): number {
    return Math.round(rating * 10) / 10;
}

export function getEndRating(coordinates?: Coordinates[]): number {
    if (!coordinates) {
        return NaN;
    }

    const ratings = coordinates.map((coordinate) => coordinate.y);
    const rating = ratings.find((r) => !!r) || NaN;
    return roundFirstDecimalPlace(rating);
}

export function getMatchSubsetByPlayer(
    match: MatchUnified,
    playerId: number,
): MatchSubsetByPlayer {
    if (match.player_1_away_id === playerId) {
        return {
            date: match.date,
            elo_confidence: match.player_1_away_elo_confidence,
            elo_end: match.player_1_away_elo_end,
            elo_scale: match.player_1_away_elo_scale,
            elo_start: match.player_1_away_elo_start,
            id: match.player_1_away_id,
            name: match.player_1_away_name,
        };
    }

    if (match.player_2_away_id === playerId) {
        return {
            date: match.date,
            elo_confidence: match.player_2_away_elo_confidence,
            elo_end: match.player_2_away_elo_end,
            elo_scale: match.player_2_away_elo_scale,
            elo_start: match.player_2_away_elo_start,
            id: match.player_2_away_id,
            name: match.player_2_away_name,
        };
    }

    if (match.player_1_home_id === playerId) {
        return {
            date: match.date,
            elo_confidence: match.player_1_home_elo_confidence,
            elo_end: match.player_1_home_elo_end,
            elo_scale: match.player_1_home_elo_scale,
            elo_start: match.player_1_home_elo_start,
            id: match.player_1_home_id,
            name: match.player_1_home_name,
        };
    }

    if (match.player_2_home_id === playerId) {
        return {
            date: match.date,
            elo_confidence: match.player_2_home_elo_confidence,
            elo_end: match.player_2_home_elo_end,
            elo_scale: match.player_2_home_elo_scale,
            elo_start: match.player_2_home_elo_start,
            id: match.player_2_home_id,
            name: match.player_2_home_name,
        };
    }

    return {
        date: "",
        elo_confidence: NaN,
        elo_end: NaN,
        elo_scale: NaN,
        elo_start: NaN,
        id: NaN,
        name: "",
    };
}

export function transformToCoordinates(
    matches: MatchUnified[],
    playerId: number,
) {
    return matches
        .map((match) => getMatchSubsetByPlayer(match, playerId))
        .filter((matchSubset) => isNotNullOrUndefined(matchSubset.elo_end))
        .map((matchSubset) => ({
            x: getDateByUtc(matchSubset.date),
            y: roundFirstDecimalPlace(matchSubset.elo_end),
        }));
}

interface MatchesRecord {
    wins: number;
    losses: number;
    ties: number;
}

export function getRecord(
    matches: MatchUnified[],
    playerId: number,
): MatchesRecord {
    const matchesRecord = {
        wins: 0,
        losses: 0,
        ties: 0,
    };

    matches.forEach((match) => {
        const playerSide = getPlayerSide(match, playerId);
        const opponentsSides = getOpponents(match, playerId);

        const winner = getWinner(match.winner);
        if (playerSide) {
            if (winner === playerSide.side) {
                matchesRecord.wins += 1;
            }

            if (winner === opponentsSides[0]?.side) {
                matchesRecord.losses += 1;
            }

            if (winner === "tie") {
                matchesRecord.ties += 1;
            }
        }
    });

    return matchesRecord;
}
