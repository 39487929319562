import * as React from "react";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import Button from "@mui/material/Button";
import Card, { CardProps } from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

type ListIconType = "add" | "remove";

export interface TrackingListItem {
    display: string;
    key: string | number;
}

interface TrackingListProps extends Omit<CardProps, "children"> {
    listIconType: ListIconType;
    listItems: TrackingListItem[];
    listKey: string;
    onIconButtonClick: (key: string | number) => void;
    actionButtonTitle?: string;
    onActionButtonClick?: () => void;
}

export default function TrackingList({
    sx,
    listIconType,
    listItems,
    listKey,
    onIconButtonClick,
    actionButtonTitle,
    onActionButtonClick,
}: TrackingListProps): JSX.Element {
    return (
        <Card sx={sx}>
            <Stack height="100%" spacing={1}>
                {listItems.map((l) => (
                    <Stack
                        key={`${l.key}-${listKey}`}
                        direction="row"
                        justifyContent="space-between"
                    >
                        <Typography>{l.display}</Typography>
                        <IconButton
                            size="small"
                            color="warning"
                            onClick={() => onIconButtonClick(l.key)}
                        >
                            {listIconType === "add" ? (
                                <AddCircleIcon />
                            ) : (
                                <RemoveCircleIcon />
                            )}
                        </IconButton>
                    </Stack>
                ))}
                {actionButtonTitle && onActionButtonClick && (
                    <div
                        style={{
                            marginTop: "auto",
                            width: "100%",
                        }}
                    >
                        <Button
                            color="secondary"
                            variant="contained"
                            disabled={listItems.length === 0}
                            fullWidth
                            onClick={() => onActionButtonClick()}
                            sx={{
                                marginTop: "auto",
                            }}
                        >
                            {actionButtonTitle}
                        </Button>
                    </div>
                )}
            </Stack>
        </Card>
    );
}
