import * as React from "react";

import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";

import ExistingUser from "./Existing";
import UserInvite from "./UserInvite";

type ShareTarget = "existing" | "new";

export default function ShareSelect(): JSX.Element {
    const [shareTarget, setShareTarget] =
        React.useState<ShareTarget>("existing");

    function handleTabChange(_: React.SyntheticEvent, v: unknown) {
        setShareTarget(v as ShareTarget);
    }

    return (
        <Stack>
            <Typography variant="h3" color="primary.main">
                Share
            </Typography>
            <Tabs
                value={shareTarget}
                onChange={(e, v) => handleTabChange(e, v)}
                variant="fullWidth"
            >
                <Tab value="existing" label="Volley User" />
                <Tab value="new" label="New User" />
            </Tabs>
            {shareTarget === "existing" && <ExistingUser />}
            {shareTarget === "new" && <UserInvite />}
        </Stack>
    );
}
