import * as React from "react";

import type {
    AppWorkoutWithRelations as AppWorkout,
    AppWorkoutListItem,
} from "@volley/data";
import { JSONObject } from "@volley/shared/common-models";

import { Sport } from "../../../common/context/sport";
import { Ranges } from "../../../hooks/ranges";

export interface WorkoutPlay {
    appWorkout: AppWorkout;
    params: JSONObject;
}

export interface AppDefinition {
    id: number;
    name: string;
    PlayComponent: React.ComponentType<AppPlayProps>;
    EditComponent: React.ComponentType<unknown>;
    ListComponent: React.ComponentType<AppListItemProps>;
    CardComponent: React.ComponentType<AppListItemProps>;
    HeroComponent: React.ComponentType<AppListItemProps>;
    IconComponent?: React.ComponentType;
    requiredFeature?: string;
    getDefaultParams: () => JSONObject;
    getDefaultConfig: () => JSONObject;
}

export type AttributeFilter = keyof Pick<
    AppWorkoutListItem,
    "globalPlayCount" | "userPlayCount" | "lastUserPlay" | "appId"
>;
export const AttributeFilterMap: Record<AttributeFilter, string> = {
    globalPlayCount: "total_play_count",
    userPlayCount: "user_play_count",
    lastUserPlay: "last_user_play",
    appId: "app_id",
};

export interface AppListItemProps {
    workout: AppWorkoutListItem;
    order?: number;
    selected?: boolean;
    showSport?: boolean;
    attributeFilter?: AttributeFilter[];
    onClick: (id: number, navigateUrl: string) => void;
}

export interface AppFetchParams {
    selectedSport: Sport;
    physicsModelName: string;
    ranges: Ranges;
}

type AppPlayProps = object;
