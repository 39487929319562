import * as React from "react";

import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Fab from "@mui/material/Fab";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { TransitionProps } from "@mui/material/transitions";

import { fetchApi } from "../../../util";
import { logFetchError } from "../../../util/fetchApi";
import { useCurrentUser } from "../../hooks/currentUser";

const Transition = React.forwardRef(
    (
        props: TransitionProps & {
            children: React.ReactElement;
        },
        ref: React.Ref<unknown>,
    ) => <Slide direction="right" ref={ref} {...props} />,
);

Transition.displayName = "FeedbackDialogTransition";

export default function FeedbackDialog() {
    const { currentUser } = useCurrentUser();
    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState("");
    const [submissionSuccess, setSubmissionSuccess] = React.useState(false);
    const [submissionFailure, setSubmissionFailure] = React.useState(false);
    const [inputErrorMssg, setInputErrorMssg] = React.useState("");

    const handleClickOpen = () => {
        setOpen(true);
        setMessage("");
        setInputErrorMssg("");
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = () => {
        if (!message) {
            setInputErrorMssg("please enter a message");
            return;
        }

        fetchApi(`/api/users/${currentUser?.id}/feedback`, "POST", {
            comment: message,
            type: "pti",
        })
            .then(() => setSubmissionSuccess(true))
            .catch((error) => {
                setSubmissionFailure(true);
                const errorMessage =
                    error instanceof Error ? error.message : "unkown";
                logFetchError(
                    error,
                    `POST /api/users/${currentUser?.id}/feedback failed [${errorMessage}]`,
                );
            });

        handleClose();
    };

    return (
        <>
            <Snackbar
                autoHideDuration={3000}
                open={submissionSuccess}
                onClose={() => setSubmissionSuccess(false)}
            >
                <Alert
                    severity="success"
                    onClose={() => setSubmissionSuccess(false)}
                    variant="filled"
                    data-testid="Player_resetAlert"
                >
                    Feedback Submitted
                </Alert>
            </Snackbar>
            <Snackbar
                open={submissionFailure}
                onClose={() => setSubmissionFailure(false)}
            >
                <Alert
                    severity="error"
                    onClose={() => setSubmissionFailure(false)}
                    variant="filled"
                    data-testid="Player_resetAlert"
                >
                    Sorry, failed to send feedback
                </Alert>
            </Snackbar>
            <Fab
                color="primary"
                onClick={handleClickOpen}
                sx={{
                    position: "absolute",
                    top: 27,
                    left: -27,
                    boxShadow: "none",
                    transform: "rotate(270deg)",
                    borderRadius: "0",
                    width: 75,
                    height: 20,
                }}
                size="small"
                variant="extended"
            >
                <Typography style={{ textTransform: "none" }} variant="body2">
                    Feedback
                </Typography>
            </Fab>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: "relative" }}>
                    <Toolbar>
                        <Typography
                            sx={{ ml: 2, flex: 1 }}
                            variant="h6"
                            component="div"
                        >
                            Feedback
                        </Typography>
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Stack sx={{ mt: 2, p: 2 }} spacing={2}>
                    <Box component="div" display="flex" justifyContent="center">
                        <Typography>
                            Please share your feedback with us below!
                        </Typography>
                    </Box>
                    <Box component="div" display="flex" justifyContent="center">
                        <TextField
                            autoFocus
                            error={!!inputErrorMssg}
                            helperText={inputErrorMssg}
                            label="message"
                            multiline
                            maxRows={15}
                            minRows={4}
                            onChange={(event) =>
                                setMessage(event.target.value.trim())
                            }
                            sx={{
                                minWidth: 325,
                                maxWidth: 500,
                            }}
                        />
                    </Box>
                    <Box component="div" display="flex" justifyContent="center">
                        <Button
                            color="secondary"
                            onClick={handleSubmit}
                            variant="contained"
                            sx={{ width: 150 }}
                        >
                            submit
                        </Button>
                    </Box>
                </Stack>
            </Dialog>
        </>
    );
}
