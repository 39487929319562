import { MatchUnified } from "@volley/data";

export const AWAY = "away";
export const HOME = "home";

export interface PlayerSide {
    player: string;
    side: string;
    row: number;
    col: number;
}

/**
 * row 1 is p1, row 2 is p2
 * col 1 is home, col 2 is away
 */
const playerSideMatrix = [
    ["player_1-home", "player_1-away"],
    ["player_2-home", "player_2-away"],
];

export function playerIdExists(match: MatchUnified, playerId: number) {
    if (
        [
            match.player_1_away_id,
            match.player_2_away_id,
            match.player_1_home_id,
            match.player_2_home_id,
        ].includes(playerId)
    ) {
        return true;
    }

    return false;
}

export function getPlayerSide(
    match: MatchUnified,
    playerId: number,
): PlayerSide | null {
    if (!playerIdExists(match, playerId)) {
        return null;
    }

    const row =
        match.player_1_away_id === playerId ||
        match.player_1_home_id === playerId
            ? 0
            : 1;
    const col =
        match.player_1_home_id === playerId ||
        match.player_2_home_id === playerId
            ? 0
            : 1;
    const cell = playerSideMatrix[row][col].split("-");

    return {
        player: cell[0],
        side: cell[1],
        row,
        col,
    };
}

export function getPartner(
    match: MatchUnified,
    playerId: number,
): PlayerSide | null {
    const player = getPlayerSide(match, playerId);

    if (!player) {
        return null;
    }

    const row = player.row === 0 ? 1 : 0;
    const cell = playerSideMatrix[row][player.col].split("-");

    return {
        player: cell[0],
        side: cell[1],
        row,
        col: player.col,
    };
}

export function getOpponents(
    match: MatchUnified,
    playerId: number,
): [PlayerSide | null, PlayerSide | null] {
    const player = getPlayerSide(match, playerId);

    if (!player) {
        return [null, null];
    }

    const col = player.col === 0 ? 1 : 0;
    const cell1 = playerSideMatrix[0][col].split("-");
    const cell2 = playerSideMatrix[1][col].split("-");

    return [
        {
            player: cell1[0],
            side: cell1[1],
            row: 0,
            col: player.col,
        },
        {
            player: cell2[0],
            side: cell2[1],
            row: 1,
            col: player.col,
        },
    ];
}

export function getPlayerKey(playerSide: PlayerSide) {
    return `${playerSide.player}_${playerSide.side}_id` as keyof MatchUnified;
}

export function getPlayerName(playerSide: PlayerSide) {
    return `${playerSide.player}_${playerSide.side}_name` as keyof MatchUnified;
}

export function isPartner(
    match: MatchUnified,
    playerId: number,
    partnerCandidateId: number,
): boolean {
    if (
        !(
            playerIdExists(match, playerId) &&
            playerIdExists(match, partnerCandidateId)
        )
    ) {
        return false;
    }

    const partner = getPartner(match, playerId);

    if (!partner) {
        return false;
    }

    const partnerId = match[getPlayerKey(partner)];
    return partnerId === partnerCandidateId;
}

export function isOpponent(
    match: MatchUnified,
    playerId: number,
    opponentCandidateId: number,
): boolean {
    if (
        !(
            playerIdExists(match, playerId) &&
            playerIdExists(match, opponentCandidateId)
        )
    ) {
        return false;
    }

    const opponents = getOpponents(match, playerId);

    if (opponents[0]) {
        const opponentId = match[getPlayerKey(opponents[0])];
        if (opponentId === opponentCandidateId) {
            return true;
        }
    }

    if (opponents[1]) {
        const opponentId = match[getPlayerKey(opponents[1])];
        if (opponentId === opponentCandidateId) {
            return true;
        }
    }

    return false;
}

/**
WinnerID WinDesc
 * 0 Score Not Entered
 * 1 Home Win
 * 2 Away Win
 * 3 Home Win by Forfeit
 * 4 Away Win by Forfeit
 * 5 Home Win by Default
 * 6 Away Win by Default
 * 7 Home Win by Retired
 * 8 Away Win by Retired
 * 9 Tie
 * 10 Double Default
 * 11 Double Forfeit
 * 12 Unknown
 * 13 Home by Early Default
 * 14 Away by Early Default
 * 15 Home by Late Default
 * 16 Away by Late Default
 * 17 Unknown
 * 18 Unknown
 * 19 Unknown
 * 20 Unknown
 * 21 Home by Protest
 * 22 Away by Protest
 */
export function getWinner(winner: number): string {
    if (winner === 9) {
        return "tie";
    }

    if ([1, 3, 5, 7, 13, 15, 21].includes(winner)) {
        return HOME;
    }

    if ([2, 4, 6, 8, 14, 16, 22].includes(winner)) {
        return AWAY;
    }

    return "unknown";
}

export function getOppositeSide(side: string) {
    return side === HOME ? AWAY : HOME;
}

export function playerWinOrLose(match: MatchUnified, playerId: number) {
    const playerSide = getPlayerSide(match, playerId);
    const outcome = getWinner(match.winner);

    if (playerSide) {
        const winner = outcome === playerSide.side;
        if (winner) {
            return "win";
        }

        if (outcome === getOppositeSide(playerSide.side)) {
            return "loss";
        }
    }

    return outcome;
}
