import * as React from "react";
import { Link as RouterLink, useSearchParams } from "react-router-dom";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import type { UrlGridClip } from "@volley/data";
import { FramePrimitive } from "@volley/shared/vision-models";

import { fetchApi, usDateFormat } from "../../util";

import ClipVideoOnCanvas from "./ClipVideoOnCanvas";

interface Props {
    id: number;
    previousId: number | null;
    nextId: number | null;
    userId: string | null;
    favorite: () => Promise<void>;
    unfavorite: () => Promise<void>;
    deleteClip: () => Promise<void>;
}

async function fetchPrimitives(
    url: string,
    contentType: string,
): Promise<FramePrimitive[]> {
    const response = await fetch(url, {
        headers: { accept: `${contentType}, text/plain, */*` },
    });
    const primitives = (await response.json()) as FramePrimitive[];
    return primitives;
}

export default function ClipViewer({
    id,
    previousId,
    nextId,
    userId,
    favorite,
    unfavorite,
    deleteClip,
}: Props): JSX.Element {
    const [searchParams] = useSearchParams();
    const [errorMessage, setErrorMessage] = React.useState("");
    const [deleteConfirmOpen, setDeleteConfirmOpen] = React.useState(false);
    const [clip, setClip] = React.useState<UrlGridClip | null>(null);
    const [primitives, setPrimitives] = React.useState<FramePrimitive[] | null>(
        null,
    );

    const onFavorite = React.useCallback(
        async (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            try {
                await favorite();
                setClip((current) =>
                    current ? { ...current, favorite: true } : null,
                );
            } catch (err: unknown) {
                setErrorMessage((err as Error).message);
            }
        },
        [favorite],
    );

    const onUnfavorite = React.useCallback(
        async (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            try {
                await unfavorite();
                setClip((current) =>
                    current ? { ...current, favorite: false } : null,
                );
            } catch (err: unknown) {
                setErrorMessage((err as Error).message);
            }
        },
        [unfavorite],
    );

    React.useEffect(() => {
        async function fetchClip() {
            const params = new URLSearchParams({
                thumbnailHeight: "360",
                primitives: "true",
            });
            if (userId) {
                params.set("userId", userId.toString());
            }
            const data = await fetchApi<UrlGridClip>(
                `/api/clips/mine/${id}?${params.toString()}`,
            );
            let primitivesData: FramePrimitive[] | null = null;
            if (data.primitives) {
                if (!data.primitives.url) return;

                try {
                    primitivesData = await fetchPrimitives(
                        data.primitives.url,
                        data.primitives.contentType,
                    );
                } catch (e: unknown) {
                    setErrorMessage((e as Error).message);
                }
            }

            setClip(data);

            if (primitivesData) {
                setPrimitives(primitivesData);
            }
        }

        fetchClip().catch((e: Error) => setErrorMessage(e.message));
    }, [id, userId]);

    if (!clip) {
        return (
            <Box
                component="div"
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                py={8}
            >
                <Typography variant="h3" component="h2" gutterBottom>
                    Loading clip…
                </Typography>
                <CircularProgress size={80} sx={{ my: 2 }} />
            </Box>
        );
    }

    return (
        <>
            <Toolbar>
                <Box component="div" flex={1}>
                    <Typography variant="h4" color="primary.main">
                        {clip.label}
                    </Typography>
                    <Typography variant="h5" color="primary.main">
                        {usDateFormat(new Date(clip.timestamp))}
                    </Typography>
                </Box>
                {/* Casting because IconButton can't take a number parameter */}
                <IconButton component={RouterLink} to={-1 as unknown as string}>
                    <CloseIcon />
                </IconButton>
            </Toolbar>
            <Stack spacing={2} mt={2}>
                {!primitives && clip.url && (
                    <video
                        autoPlay
                        loop
                        muted
                        controls
                        // The `playsInline` attribute forces Safari to not fullscreen auto-playing videos like this
                        // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/video#playsinline
                        playsInline
                        src={clip.url}
                        style={{
                            marginLeft: 8,
                            marginRight: 8,
                            aspectRatio: "16 / 9",
                        }}
                        poster={clip.thumbnail?.url}
                    />
                )}
                {primitives && clip.url && (
                    <ClipVideoOnCanvas
                        videoUrl={clip.url}
                        posterUrl={clip.thumbnail?.url}
                        primitives={primitives}
                    />
                )}
                <Box component="div" display="flex" px={1}>
                    <Box component="div" flex={1}>
                        <IconButton onClick={() => setDeleteConfirmOpen(true)}>
                            <DeleteIcon />
                        </IconButton>
                    </Box>
                    <Box component="div">
                        {clip.favorite ? (
                            <IconButton size="large" onClick={onUnfavorite}>
                                <FavoriteIcon color="secondary" />
                            </IconButton>
                        ) : (
                            <IconButton size="large" onClick={onFavorite}>
                                <FavoriteBorderIcon />
                            </IconButton>
                        )}
                    </Box>
                </Box>
                {!!errorMessage && (
                    <Typography color="error.main" px={2}>
                        {errorMessage}
                    </Typography>
                )}
                <Box
                    component="div"
                    display="flex"
                    justifyContent="space-between"
                    px={1}
                    pb={4}
                >
                    <Box component="div" width="40%">
                        {previousId && (
                            <Button
                                size="large"
                                startIcon={<ChevronLeftIcon />}
                                variant="contained"
                                color="info"
                                fullWidth
                                component={RouterLink}
                                to={`../clips/mine/${previousId}?${searchParams.toString()}`}
                                replace
                            >
                                Previous
                            </Button>
                        )}
                    </Box>
                    <Box component="div" width="40%">
                        {nextId && (
                            <Button
                                size="large"
                                endIcon={<ChevronRightIcon />}
                                variant="contained"
                                color="info"
                                fullWidth
                                component={RouterLink}
                                to={`../clips/mine/${nextId}?${searchParams.toString()}`}
                                replace
                            >
                                Next
                            </Button>
                        )}
                    </Box>
                </Box>
            </Stack>

            <Dialog
                open={deleteConfirmOpen}
                onClose={() => setDeleteConfirmOpen(false)}
                fullWidth
            >
                <Toolbar>
                    <Typography
                        variant="h4"
                        component="div"
                        flex={1}
                        color="primary.main"
                    >
                        Confirm Clip Deletion
                    </Typography>
                    <IconButton onClick={() => setDeleteConfirmOpen(false)}>
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
                <Divider />
                <Stack spacing={2} p={2}>
                    <Typography align="center">
                        Are you sure? Once deleted you won&apos;t be able to
                        recover this clip.
                    </Typography>
                    {!!errorMessage && (
                        <Typography color="error.main">
                            {errorMessage}
                        </Typography>
                    )}
                    <Button
                        fullWidth
                        color="error"
                        size="large"
                        variant="contained"
                        onClick={() =>
                            deleteClip().catch((e: Error) =>
                                setErrorMessage(e.message),
                            )
                        }
                    >
                        Delete Clip
                    </Button>
                    <Button
                        fullWidth
                        color="primary"
                        size="large"
                        variant="contained"
                        onClick={() => setDeleteConfirmOpen(false)}
                    >
                        Cancel
                    </Button>
                </Stack>
            </Dialog>
        </>
    );
}
