import * as React from "react";
import { Blurhash } from "react-blurhash";
import { Link as RouterLink, useSearchParams } from "react-router-dom";

import FavoriteIcon from "@mui/icons-material/Favorite";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";

import type { GridClip } from "@volley/data";

export default function ClipRow({ clip }: { clip: GridClip }): JSX.Element {
    const theme = useTheme();
    const [searchParams] = useSearchParams();
    const [loading, setLoading] = React.useState(true);

    return (
        <ImageListItem
            component={RouterLink}
            to={`../clips/mine/${clip.id}?${searchParams.toString()}`}
            sx={{ textDecoration: "none", color: "text.primary" }}
        >
            {clip.thumbnail ? (
                <img
                    src={clip.thumbnail.url}
                    alt="clip thumbnail"
                    style={{ display: loading ? "none" : undefined }}
                    onLoad={() => setLoading(false)}
                />
            ) : (
                <Typography
                    sx={{ aspectRatio: "16 / 9", pt: 2 }}
                    variant="caption"
                    align="center"
                >
                    Preview unavailable
                </Typography>
            )}
            {loading && clip.thumbnail && (
                <Blurhash
                    hash={clip.thumbnail.blurHash}
                    width="auto"
                    height="100%"
                    style={{ aspectRatio: "16 / 9" }}
                />
            )}
            {clip.favorite && (
                <ImageListItemBar
                    position="top"
                    sx={{ background: "transparent" }}
                    actionIcon={
                        <FavoriteIcon
                            sx={{
                                color: theme.palette.secondary.main,
                                fontSize: "1rem",
                            }}
                        />
                    }
                />
            )}
        </ImageListItem>
    );
}
