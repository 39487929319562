import * as React from "react";

import SearchIcon from "@mui/icons-material/Search";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import TextField from "@mui/material/TextField";

import { GridRowBaseDef } from "../WinLossTables/BaseTable";

export interface AutoCompleteProps {
    selectionOptions: GridRowBaseDef[];
    initialValues?: GridRowBaseDef[];
    filterValues?: GridRowBaseDef[];
    label: string;
    handleSelectValue?: (directoryEntry: GridRowBaseDef | null) => void;
    handleSelectValues?: (directory: GridRowBaseDef[] | null) => void;
    multiple?: boolean;
}

export function getOptionLabel(row: GridRowBaseDef): string {
    if (!row) {
        return "";
    }
    return row.autoCompleteValue || "";
}

export default function TableAutoComplete({
    selectionOptions,
    initialValues = [],
    filterValues = [],
    label,
    multiple = false,
    handleSelectValue = () => {},
    handleSelectValues = () => {},
}: AutoCompleteProps): JSX.Element {
    const [open, setOpen] = React.useState(false);
    const loading = selectionOptions.length === 0;
    const [defaultValues, setDefaultValues] = React.useState<
        GridRowBaseDef | GridRowBaseDef[] | undefined
    >(multiple ? [] : undefined);

    React.useEffect(() => {
        if (selectionOptions.length > 0) {
            if (multiple) {
                setDefaultValues(initialValues);
            } else {
                setDefaultValues(initialValues[0]);
            }
        }
    }, [initialValues, multiple, selectionOptions]);

    const options = selectionOptions.filter(
        (option) => !filterValues.includes(option),
    );

    return (
        <Autocomplete
            multiple={multiple}
            id={`${label}s-auto-complete`}
            isOptionEqualToValue={(option, value) => {
                if (option && value) {
                    return option.autoCompleteValue === value.autoCompleteValue;
                }

                return true;
            }}
            filterOptions={createFilterOptions({ limit: 5 })}
            fullWidth
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            options={options}
            getOptionLabel={getOptionLabel}
            noOptionsText="No options"
            onChange={(
                _event,
                selection: GridRowBaseDef | GridRowBaseDef[] | null,
            ) => {
                if (selection instanceof Array) {
                    handleSelectValues(selection);
                } else {
                    handleSelectValue(selection);
                }
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    slotProps={{
                        input: {
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {loading ? (
                                        <CircularProgress
                                            color="inherit"
                                            size={20}
                                        />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                </>
                            ),
                        },
                    }}
                />
            )}
            popupIcon={<SearchIcon />}
            value={defaultValues}
            sx={{
                ".MuiAutocomplete-popupIndicatorOpen": {
                    transform: "none",
                },
            }}
            data-testid="TableAutoComplete_autoComplete"
        />
    );
}
