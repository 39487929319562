import * as React from "react";

import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListSubheader from "@mui/material/ListSubheader";
import Typography from "@mui/material/Typography";

import { AppWorkoutListItem } from "@volley/data";

import { AppId, findApp } from "../apps";
import { AttributeFilter } from "../apps/types";

export interface SubListProps {
    attributeFilter: AttributeFilter[];
    label: string;
    selected: number | null;
    workouts: AppWorkoutListItem[];
    filter: (workout: AppWorkoutListItem) => boolean;
    onClick: (id: number, navigateUrl: string) => void;
}

export default function SubList({
    attributeFilter,
    label,
    selected,
    workouts,
    filter,
    onClick,
}: SubListProps): JSX.Element | null {
    const workoutsToRender = React.useMemo(() => {
        const filtered = workouts.filter(filter);
        return filtered;
    }, [workouts, filter]);

    if (workoutsToRender.length === 0) {
        return null;
    }

    return (
        <List>
            <ListSubheader
                sx={{
                    backgroundColor: (t) => t.palette.primary.dark,
                }}
            >
                <Typography
                    variant="h4"
                    color="white"
                    sx={{
                        textTransform: "none",
                    }}
                >
                    {label}
                </Typography>
            </ListSubheader>
            {workoutsToRender.map((workout) => {
                const app = findApp(workout.appId as AppId);
                if (!app || app.ListComponent === null) {
                    return null;
                }

                return (
                    <>
                        <app.ListComponent
                            key={workout.id}
                            selected={selected === workout.id}
                            workout={workout}
                            onClick={onClick}
                            attributeFilter={attributeFilter}
                        />
                        <Divider color="white" />
                    </>
                );
            })}
        </List>
    );
}
