import * as React from "react";

import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import type { GridColDef, GridRowParams } from "@mui/x-data-grid";

import { MatchUnified } from "@volley/data";

import { terseDateFormat } from "../../../util";
import { MatchRow } from "../Matches/MatchesTable";
import { AWAY, HOME, getPlayerSide, playerWinOrLose } from "../utils/labels";
import {
    formatNumberSign,
    formatTwoDecimal,
    getDateByUtc,
    getMatchesDesc,
} from "../utils/util";

import BaseTable, { GridRowBaseDef } from "./BaseTable";

interface SpreadRow extends GridRowBaseDef {
    date: string;
    win_loss: string;
    spread: number;
}

interface Props {
    matches: MatchUnified[];
    playerId: number;
}

function calculateSpread(match: MatchUnified, playerId: number) {
    const homeTeamPti =
        match.player_1_home_elo_start + match.player_2_home_elo_start;
    const awayTeamPti =
        match.player_1_away_elo_start + match.player_2_away_elo_start;

    if (homeTeamPti === awayTeamPti) {
        return 0;
    }

    const spread = Math.abs(homeTeamPti - awayTeamPti);
    const playerSide = getPlayerSide(match, playerId);

    if (!playerSide) {
        return NaN;
    }

    // PTI is an inverse scale, the lower pti, the better skill level
    if (playerSide.side === HOME && homeTeamPti > awayTeamPti) {
        return formatTwoDecimal(spread); // underdog
    }

    if (playerSide.side === AWAY && awayTeamPti > homeTeamPti) {
        return formatTwoDecimal(spread); // underdog
    }

    // Favorite to win
    return formatTwoDecimal(spread) * -1;
}

export default function SpreadTable({ matches, playerId }: Props): JSX.Element {
    const matchesDesc = getMatchesDesc(matches);
    const [open, setOpen] = React.useState(false);

    // table rows
    const [selectedLocationRow, setSelectedPartnerRow] =
        React.useState<SpreadRow | null>(null);

    const cols: GridColDef[] = [
        {
            field: "spread",
            headerName: "Spread",
            align: "left",
            sortable: true,
            disableColumnMenu: true,
            width: 115,
            renderCell: (params) => {
                const row = params.row as SpreadRow;
                return <span>{formatNumberSign(row.spread)}</span>;
            },
        },
        {
            field: "date",
            headerName: "Date",
            align: "left",
            sortable: true,
            disableColumnMenu: true,
            width: 105,
            sortComparator: (d1: string, d2: string) => {
                const date1 = new Date(getDateByUtc(d1));
                const date2 = new Date(getDateByUtc(d2));
                if (date1 <= date2) {
                    return 1;
                }
                return -1;
            },
            renderCell: (params) => {
                const row = params.row as SpreadRow;
                return <span>{terseDateFormat(getDateByUtc(row.date))}</span>;
            },
        },
        {
            field: "win_loss",
            headerName: "W/L",
            align: "left",
            sortable: true,
            width: 80,
            disableColumnMenu: true,
        },
    ];

    const rows: SpreadRow[] = matchesDesc.map((match, i) => ({
        id: i,
        date: match.date,
        spread: calculateSpread(match, playerId),
        win_loss: playerWinOrLose(match, playerId),
    }));

    const handleOnRowClick = (e: GridRowParams<SpreadRow>) => {
        const { row } = e;
        setSelectedPartnerRow(row);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Dialog open={open} onClose={handleClose} scroll="body">
                <DialogTitle>Match</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent sx={{ pl: 1, pr: 1 }}>
                    {selectedLocationRow?.id !== undefined &&
                    selectedLocationRow.id !== null ? (
                        MatchRow({
                            match: matchesDesc[selectedLocationRow.id],
                            playerId,
                        })
                    ) : (
                        <span>n/a</span>
                    )}
                </DialogContent>
            </Dialog>
            <BaseTable
                rows={rows}
                cols={cols}
                initialSortField="date"
                handleOnRowClick={handleOnRowClick}
            />
        </>
    );
}
