import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { getApp, getAppId } from "./apps";

export default function EditPlugin(): JSX.Element {
    const { appId: appIdMaybe } = useParams<{ appId: string }>();
    const navigate = useNavigate();

    const appId = getAppId(appIdMaybe);

    if (!appId) {
        return (
            <Stack component="div">
                <Typography>Something went wrong.</Typography>
                <Typography>
                    Please go back and try to select another option.
                </Typography>
                <Button
                    variant="contained"
                    color="info"
                    fullWidth
                    onClick={() => navigate(-1)}
                >
                    Back
                </Button>
            </Stack>
        );
    }

    const app = getApp(appId);

    return <app.EditComponent />;
}
