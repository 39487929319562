import * as React from "react";

import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function PlayerIcon(props: SvgIconProps): JSX.Element {
    return (
        <SvgIcon viewBox="0 0 48 48" {...props}>
            <path d="m26.44,4c2.21,0,4,1.79,4,4s-1.79,4-4,4-4-1.79-4-4,1.79-4,4-4Zm14.21,12.93l-9.99,1.07-5.03,9.32,5.89,5.94-1.09,10.75h-3.17l-.03-9.07-5.23-2.93h-1.89l-6.89,12h-3.79l9.78-26-11.87-1.07v-2.93h33.3v2.93Z" />
        </SvgIcon>
    );
}
